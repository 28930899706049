import React, {  useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import StructuredPanel from './StructuredPanel';
import ShowIf from '../Common/ShowIf';
import { isFigureOrSidebar, isIndexedComponent } from '../../helpers/getComponentInfo';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import { usePageContext } from '../../contexts/PageContext';
import { useArticleContext } from '../../contexts/ArticleContext';
import HTMLContent from '../Common/HTMLContent';
import { REVISION_HISTORY } from '../Constants/Constants';
export const StructuredSection = (props) => {
	const { context } = usePageContext();

	const structuredSectionTitle = UTILITIESHELPER.getContentItemTitle(props.item.content);
	const titleRendered = 
		structuredSectionTitle !== ""

	const { searchText, getIsSectionExpanded, setOpenMultipleExpandedSections, removeExpandedSectionKey } = useArticleContext();
	const uuidRef = useRef(UTILITIESHELPER.generateUUID());
	const uuid = uuidRef.current;
	const isSectionExpanded = getIsSectionExpanded(uuid);
	const isIndexed = isIndexedComponent(props.item.content_type);

	useEffect(() => {
		//ONLY within a FOLIO, and when on qna|templates and we have more then 1 section, then they need to start contracted
		const isDefaultExpanded = (context.pageSelectedSection === "") ||
			(("|qna|templates|rp-templates".indexOf(context.pageSelectedSection) > 0) && (props.sections === 1)) ||
			("|qna|templates|rp-templates".indexOf(context.pageSelectedSection) === -1);

		setOpenMultipleExpandedSections([uuid], isDefaultExpanded)
		return () => removeExpandedSectionKey(uuid);
	}, []) //eslint-disable-line

	function getSectionTitleMarkup() {
		if (props.level === null) {
			return null;
		}
		return `<h${props.level + 1}>${structuredSectionTitle}</h${props.level + 1}>`
	}
	const renderTitle = 
		!isFigureOrSidebar(props.item.content_type) && 
		!isIndexed && 
		!(props.item.content_type===REVISION_HISTORY.revision_history) && 
		structuredSectionTitle;
		
	var jumpClass = 'jumplink-' + props.item.id;

	return (
		<div
			id={props.item.id}
			data-expandable-uuid={uuid}
			className={isSectionExpanded ? '' + jumpClass + ' structured-article-section open' : '' + jumpClass + ' structured-article-section'}
		>
			<ShowIf condition={renderTitle}>
				<div className="article-sub-title-button-container">
					<div className={isSectionExpanded ? 'expanded-title' : 'collapsed-title'}>
						<p className={isSectionExpanded ? '' : 'closed'} onClick={() => setOpenMultipleExpandedSections([uuid], 'toggle')}></p>
						<span>
							<HTMLContent>{getTextWithSearchHighlight(getSectionTitleMarkup(), searchText)}</HTMLContent>
						</span>
					</div>
				</div>
			</ShowIf>
			<StructuredPanel
				item={props.item}
				level={props.level}
				parentTitle={structuredSectionTitle}
				publication={props.publication}
				isEmbedded={props.isEmbedded}
				isIndexed={isIndexed}
				titleRendered={titleRendered}
				pageType={props.pageType}
			/>
		</div>
	);
}

StructuredSection.propsTypes = {
	title: PropTypes.string,
	typeName: "StructuredSection"
}

export default StructuredSection;