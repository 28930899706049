import { useEffect, useState,useRef } from 'react';
import { useQuery } from 'react-query';
import RETRIEVALSERVICES from './rp-service';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import CONTEXTHELPERS from "../helpers/ContextHelpers";
import { FOLIO_SUBJECT_ACCOUNT, FOLIO_SUBJECT_NON_ACCOUNT, Learning_Resources_Dummy_Response, REGEX_PATTERN } from '../components/Constants/Constants';
import UTILITIESHELPER from '../helpers/UtilitiesHelper';
import ProfileHelpers from '../helpers/ProfileHelpers';
import { logs, page } from '../helpers/log';
import { ErrorCodes, ErrorMessages, ResearchAssisantErrorMessages } from '../components/Constants/Errors';
import { usePageContext, usePageContextLabels } from '../contexts/PageContext';
import { PAGE_CONTROLLER_TYPE,PUBLICATIONTYPE_IDS } from '../components/Constants/Constants.js';



/**
 * Custom hook to get User Information API using bearer token obtained from MSAL
 * @returns
 */
function useDCSService() {
    const { apiCall } = useFetchWithMsal();
    const { getLabel } = usePageContextLabels();
    const { context, getMemberFirmIDs, getMemberFirmLanguages, getMemberFirmID,countryKDSettings } = usePageContext();
    const memberfirm = context.memberFirm;
    const language = context.language;
    const DCSEndPoint = (window.DART && window.DART.ENV) ? window.DART.ENV.REACT_APP_DCS_ENDPOINT_API : undefined;
    const KnowledgeDomain = context.knowledgeDomain;
    const SearchEndPoint = (window.DART && window.DART.ENV) ? window.DART.ENV.REACT_APP_SEARCH_ENDPOINT_API : undefined;
    const customheaders = { "INK_CONTENT_PRERELEASE": (window.DART && window.DART.ENV) &&  (window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE ? window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE : window.DART.ENV.RP_REACT_APP_PREVIEW) };
    /**
     * Execute a fetch request with the given options
     * @returns JSON response
     * @param publicationType
     */
    // Used to prevent state updates after unmount
    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    //PRIVATE FUNCTIONS:
    const formatLink = (item, querySpec, collectionType) => {
        if (collectionType) {
            collectionType = (collectionType !== PAGE_CONTROLLER_TYPE.standards) ? collectionType : (item.publication_type !== PUBLICATIONTYPE_IDS.standards) ? PAGE_CONTROLLER_TYPE.standard :collectionType; 
            const linkExternal = [collectionType, (item.url.replaceAll('%2f','/'))].join("/");
            const url = [window.location.origin,memberfirm,language,KnowledgeDomain,linkExternal].join("/");
            return {
                linkText: (collectionType === "deloitte-communications" || collectionType === "professional-news") && item?.journal_brand ? item.journal_brand + ": " + item.title : item.title,
                PublicationGUID: item.id,
                PublicationType:  UTILITIESHELPER.isStringNullorEmpty(item.publication_type) ? null : item.publication_type ,
                linkInternal: {
                    Id: item.id,
                    LinkUrl: (item.url.replaceAll('%2f','/')),
                    PublicationGUID: item.id
                },
                linkDocs: null,
                linkExternal,
                linkAltText: item.alt_title,
                url:url,
                context : item.context
            }
        }

        return item;
    }

    const formatDelCummAndProfNewsCollectionLink = (data, querySpec, collectionType) => {
        let collectionObject = { alllinkItems: [], allcollectionsItems : []}
        let allLinksItems  = [];
        if (collectionType) {
            allLinksItems =  data.flatMap(journal => journal.items)?.sort((a, b) => new Date(b.current_date) - new Date(a.current_date)).map(item => {
                const linkExternal = [collectionType, (item.url.replaceAll('%2f', '/'))].join("/");
                const url = [window.location.origin, memberfirm, language, KnowledgeDomain, linkExternal].join("/");
                return {
                    linkText: (collectionType === "deloitte-communications" || collectionType === "professional-news") && item?.journal_brand ? item.journal_brand + ": " + item.title : item.title,
                    PublicationGUID: item.id,
                    PublicationType: UTILITIESHELPER.isArrayNullorEmpty(querySpec.publication_type) ? null : querySpec.publication_type[0],
                    linkInternal: {
                        Id: item.id,
                        LinkUrl: (item.url.replaceAll('%2f', '/')),
                        PublicationGUID: item.id
                    },
                    linkDocs: null,
                    linkExternal,
                    linkAltText: item.alt_title,
                    url: url,
                    context: item.context
                }
            })
        }
        collectionObject.alllinkItems = allLinksItems;
        collectionObject.allcollectionsItems = data;
        return collectionObject;
    }

    //PUBLIC FUNCTIONS
    const getQueryKey = (querySpec,keyprefix) => {
        let queryKey = `${keyprefix}-${[...querySpec.knowledge_domain]}-${querySpec.member_firm ? [...querySpec.member_firm] : getMemberFirmIDs()}`;
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.publication_type)) {
            queryKey = `${queryKey}-${querySpec.publication_type}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.tier)) {
            queryKey = `${queryKey}-${[...querySpec.tier]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.industry)) {
            queryKey = `${queryKey}-${[...querySpec.industry]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.audit_workflow_area_account)) {
            queryKey = `${queryKey}-${[...querySpec.audit_workflow_area_account]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.audit_workflow_area_nonaccount)) {
            queryKey = `${queryKey}-${[...querySpec.audit_workflow_area_nonaccount]}`;
        }
        return queryKey
    }

    const generateQuerySpec = (publicationType, result_limit, isTaxonomy=false) => {
     
        const knowledgeDomainID = CONTEXTHELPERS.getknowledgeDomainId(context.knowledgeDomain);

        let audit_workflow_area_account = [];
        let audit_workflow_area_nonaccount = [];
        //Dynamic queries are only there in Guidebooks (for now)
        if (context.pageControllerType === "folio")
        {
            //e.g. Direction-Supervision-and-Review_66019141-66029848
            const pageDynamicQuery = context.pageFileName.split("_");
            if (pageDynamicQuery.length > 1 && !isTaxonomy )
            {
                //e.g. 66019141-66029848
                const pageDynamicCategory = pageDynamicQuery[1].split("-");

                if (pageDynamicCategory.length > 1 )
                {
                    //e.g. 66019141
                    switch (pageDynamicCategory[0])
                    {
                        case FOLIO_SUBJECT_ACCOUNT.accountBalance:
                        case FOLIO_SUBJECT_ACCOUNT.auditingAccountingFolios:
                        case FOLIO_SUBJECT_ACCOUNT.accountingFolios:
                            audit_workflow_area_account = [ pageDynamicCategory[1] ]; //e.g. 66029848
                            break;

                        case FOLIO_SUBJECT_NON_ACCOUNT.nonAccountBalance:
                        case FOLIO_SUBJECT_NON_ACCOUNT.auditingNonAccountFolios:
                        case FOLIO_SUBJECT_NON_ACCOUNT.assuranceFolios:
                            audit_workflow_area_nonaccount = [ pageDynamicCategory[1] ]; //e.g. 66029848
                            break;
                    }
                }
            }
        }
        
        return {
            toc: false,
            result_limit: result_limit,
            member_firm: getMemberFirmIDs(),
            default_language: context.language,
            languages: getMemberFirmLanguages(),
            knowledge_domain: [knowledgeDomainID],
            publication_type: publicationType ? [publicationType] : null,
            tier: CONTEXTHELPERS.getTierIds(context.memberFirm, context.knowledgeDomain),
            industry: CONTEXTHELPERS.getIndustryIds(context.memberFirm, context.knowledgeDomain),
            audit_workflow_area_account,
            audit_workflow_area_nonaccount,
            effective_date: new Date(),
            version: 0
        }
    }

    const raErrorHandler = (request, error, response) =>{
        logs.error(page.DCSService, 'RPService', "Error in chatBot search api", request.url);
        switch (true) {
            case REGEX_PATTERN.error4xxSeries.test(error.message):
                error.message = getLabel('err_RaUnableToRetrieve400', ResearchAssisantErrorMessages.ra_errormessage_400);
                return error;
            case REGEX_PATTERN.error5xxSeries.test(error.message):
                error.message = getLabel('err_RaUnableToRetrieve500', ResearchAssisantErrorMessages.ra_errormessage_500);
                return error;
            case !REGEX_PATTERN.anyDigit.test(error.message):
                error.message = getLabel('err_RaUnableToRetrieve500', ResearchAssisantErrorMessages.ra_errormessage_500);
                return error;
            default:
                return response;
        }
    }

    async function getCatalogSections(requestbody, collectionType) {
        let dataFormated = [];
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        if (KnowledgeDomain === "accounting") {
            collectionType = collectionType === "manual" ? "guidance" : collectionType;
        }
        else {
            collectionType = collectionType === "guidance" ? "manual" : collectionType;

        }
        let request = {
            url: `${DCSEndPoint}/catalog/${KnowledgeDomain}/${collectionType}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            dataFormated = (UTILITIESHELPER.isNullOrUndefined(data) || data.length === 0) ? [] : data.map(item => formatLink(item, requestbody, collectionType));
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:[retrieveLinks collectionType, dataFormated]:", collectionType, dataFormated);
        }
        catch (error) {
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections DEBUG ERROR:[collectionType, request, error]:", collectionType, request, error);
            logs.error(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:" + ErrorMessages.catalogSections + " for  collection " + collectionType, error, { eventId: ErrorCodes.Catalog });
            dataFormated = null;
        }

        return dataFormated;
    };

    //For Announcement collection page
    async function getDelCummAndProfNewsCollectionSections(requestbody, collectionType, collectionName) {
        let dataFormated = [];
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        if (KnowledgeDomain === "accounting") {
            collectionType = collectionType === "manual" ? "guidance" : collectionType;
        }
        else {
            collectionType = collectionType === "guidance" ? "manual" : collectionType;

        }
        let request = {
            url: `${DCSEndPoint}/catalog/${KnowledgeDomain}/${collectionName}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            dataFormated = (UTILITIESHELPER.isNullOrUndefined(data) || data.length === 0) ? [] : formatDelCummAndProfNewsCollectionLink(data, requestbody, collectionType);
            logs.debug(page.DCSService, 'getDelCummAndProfNewsCollectionSections', "useDCSService::getDelCummAndProfNewsCollectionSections:[retrieveLinks collectionType, dataFormated]:", collectionType, dataFormated);
        }
        catch (error) {
            logs.debug(page.DCSService, 'getDelCummAndProfNewsCollectionSections', "useDCSService::getDelCummAndProfNewsCollectionSections DEBUG ERROR:[collectionType, request, error]:", collectionType, request, error);
            logs.error(page.DCSService, 'getDelCummAndProfNewsCollectionSections', "useDCSService::getDelCummAndProfNewsCollectionSections:" + ErrorMessages.catalogSections + " for  collection " + collectionType, error, { eventId: ErrorCodes.Catalog });
            dataFormated = null;
        }

        return dataFormated;
    };
    async function getFolioSection(requestbody, sectionFriendlyPath) {
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        const learningResourcePayload = {
            "member_firm": requestOptions.body.member_firm,
            "audit_workflow_area_nonaccount": requestOptions.body.audit_workflow_area_nonaccount

        }
        let request = {
            url: `${DCSEndPoint}/folio/${sectionFriendlyPath}`,
            method: 'POST',
            mode: 'cors',
            body:  JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.length === 0) ? [] : data;
            //logs.debug(page.DCSService, 'getFolioSection', "useDCSService::getFolioSection[sectionFriendlyPath, dataFormated]:", sectionFriendlyPath, dataFormated);
            return dataFormated;
        }
        catch (error) {
            logs.debug(page.DCSService, 'getFolioSection', "useDCSService::getFolioSection DEBUG ERROR:[sectionFriendlyPath, request, error]:", sectionFriendlyPath, request, error);
            logs.error(page.DCSService, 'getFolioSection', "useDCSService::getFolioSection:" + ErrorMessages.folioSections + " for section " + sectionFriendlyPath, error, { eventId: ErrorCodes.FolioSectionTOC });
            //dataFormated = null;
            return [];
        }

    };

    async function retrievePublicationForTopic(requestbody, topicId, publicationId)
    {
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        let request = {
            url: `${DCSEndPoint}/publication/${publicationId}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.length === 0) ? [] : data;
            return dataFormated;
        }
        catch (error) {
            logs.debug(page.DCSService, 'retrievePublication', "useDCSService::retrievePublication DEBUG ERROR:[topicGuid, request, error]:", publicationId, request, error);
            logs.error(page.DCSService, 'retrievePublication', "useDCSService::retrieveTopic:" + ErrorMessages.linkTopic + " for topic " + publicationId, error, { eventId: ErrorCodes.Link });
            return [];
        }
    }

    async function retrieveChatBotSearchResult(payload) {
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(payload);
        let request = {
            url: `${SearchEndPoint}/ResearchAssistant/search`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }
       
        //retrieve file and trigger download on user's side
        //Added a try catch and await ,since Promise  .then is not resolving here 
        var response=null;
        try {
             response = await apiCall(request, customheaders);
             return response;
        }
        catch(error) {
            var result = raErrorHandler(request, error, response);
            return result;
        }
    }

    async function researchAssistantUserFeedback(payload) {
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(payload);
        let request = {
            url: `${SearchEndPoint}/ResearchAssistant/userFeedBack`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }
       
        var response=null;
        try {
             response = await apiCall(request, customheaders);
            
        }
        catch {
            logs.error(page.DCSService, 'RPService', "Error in Research Assistant user feedback api", request.url);
        }
        finally {
            return response;
        }
    }
    async function retrieveFrameworkList(taxonomyMemberFirmContext) {
        let request = {
            url: `${DCSEndPoint}/taxonomy/framework?memberFirmIds=${taxonomyMemberFirmContext}&asHierarchical=true&includeRelations=true`,
            method: 'GET',
            mode: 'cors'
        }

        try {
            const data = await apiCall(request, customheaders);
            return data
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:[retrieveLinks dataFormated]:", data);
        }
        catch (error) {
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections DEBUG ERROR:[request, error]:", request, error);
            logs.error(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:" + ErrorMessages.catalogSections + " for  collection " + error, { eventId: ErrorCodes.Catalog });
            return null;
        }
    }


    async function retrieveFolioTaxonomyLists(requestbody, pubLandingUrl)
    {
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        let url = '';
        var taxonomyType = pubLandingUrl.match(/_(\d+)/);
        const taxonomyTypeId = taxonomyType ? taxonomyType[0] : '';
        console.log("taxonomyTypeId =>", taxonomyTypeId);
        switch (true) {
            case pubLandingUrl.includes(FOLIO_SUBJECT_NON_ACCOUNT.auditingNonAccountFolios):
                url = `publication/nonAccountsList`;
                break;
            case pubLandingUrl.includes(FOLIO_SUBJECT_ACCOUNT.auditingAccountingFolios):
                url = `publication/AccountsList`;
                break;
            case pubLandingUrl.includes(FOLIO_SUBJECT_NON_ACCOUNT.assuranceFolios):
                url = `publication/assuranceNonAccountsList`;
                break;
        }
        console.log("URL =>", url)
        let request = {
            url: `${DCSEndPoint}/${url}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }
 
        try {
            const data = await apiCall(request, customheaders);
            const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.length === 0) ? [] : data;
            return dataFormated;
        }
        catch (error) {
            logs.debug(page.DCSService, 'retrievePublication', "useDCSService::retrievePublication DEBUG ERROR:[ request, error]:", request, error);
            logs.error(page.DCSService, 'retrievePublication', "useDCSService::retrieveTopic:" + ErrorMessages.linkTopic + " for topic " +  error, { eventId: ErrorCodes.Link });
            return [];
        }
    }

    async function retrieveFolioLists(taxonomyMemberFirmContext)
    {
        let request = {
            url: `${DCSEndPoint}/taxonomy/folios?memberFirmIds=${taxonomyMemberFirmContext}&asHierarchical=true&includeRelations=true`,
            method: 'GET',
            mode: 'cors'
        }

        try {
            const data = await apiCall(request, customheaders);
            return data
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:[retrieveLinks dataFormated]:", data);
        }
        catch (error) {
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections DEBUG ERROR:[request, error]:", request, error);
            logs.error(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:" + ErrorMessages.catalogSections + " for  collection " + error, { eventId: ErrorCodes.Catalog });
            return null;
        }
    }


    //NOTE: YOU HAVE TO INCLUDE YOUR USE CODE EXACTLY AS THEY APEAR:
    //const { generateQuerySpec, getQueryKey, getCatalogSections, getFolioSection } = useDCSService();
    return {
        //Public Functions
        generateQuerySpec,
        getQueryKey,
        //Retreival Functions
        getCatalogSections,
        getFolioSection,
        retrieveChatBotSearchResult,
        researchAssistantUserFeedback,
        retrievePublicationForTopic,
        getDelCummAndProfNewsCollectionSections,
        retrieveFrameworkList,
        retrieveFolioTaxonomyLists,
        retrieveFolioLists
    };
};

export default useDCSService;
