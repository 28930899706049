import React from 'react'
import { usePageContextLabels } from '../../contexts/PageContext';
import ShowIf from '../Common/ShowIf';
import { RESEARCH_ASSISTANT } from '../Constants/Constants';
import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";

const ResearchAssistantMessage = ({ messageLabel, message }) => {
    const { getLabel } = usePageContextLabels();

    const sanitizedData = DOMPurify.sanitize(message);
    return (<>
        <p >{ReactHtmlParser(sanitizedData)}</p>
        <ShowIf condition={messageLabel === RESEARCH_ASSISTANT.AMBIGUOUS || messageLabel === RESEARCH_ASSISTANT.UNANSWERABLE}>
            <p>{getLabel('ra_unable_to_answer_1', 'I can provide helpful answers to questions regarding audit concepts. I have access to audit policies and guidance.')}</p>
            <p>{getLabel('ra_unable_to_answer_2',  RESEARCH_ASSISTANT.unableToAnswerText)}</p>
        </ShowIf>
    </>);
}

export default ResearchAssistantMessage