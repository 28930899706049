
import { CONTENT_TYPE_IDS, MEMBERFIRM_CONSTANTS } from '../components/Constants/Constants';
import ARTICLEHELPERS from './ArticleHelpers';



function log(message) {
    if (window.DART.ENV.RP_REACT_APP_PREVIEW === 'true') {
        //For when we want to have a stacktrace to see where the message is coming from use this:
        console.log(message);
    }
}

function getIntFromStr(stringVal) {
    const matches = stringVal.match(/(\d+)/);
    if (matches) {
      return matches[0];
    }
}

function sortArray(array, property){
    if(Array.isArray(array) && array.length > 0) {
        return array.sort(dynamicSort(property)).sort(dynamicSort(property));
    }else {
        return array;
    }
}

function dynamicSort(property) {
    return function(a, b) {
        return (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    }
}

function sortArrayDesc(array, property) {
    if (Array.isArray(array) && array.length > 0) {
        return array.sort(dynamicSortDesc(property));
    }
    else {
        return array;
    }
}
function dynamicSortDesc(property) {
    return function (a, b) {
        return (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
    }
}


function sortAlphaNumericArray(array) {
  return array.sort((a, b) => {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
}
function sortAlphaCaseInsensitive(array,property) {
    return array.sort((a, b) => {
      return a[property].localeCompare(b[property], undefined, {
        sensitivity: "base",
      });
    });
  }
function stickyBarResizing() {
    const stickyBar = document.querySelector('.sticky-bar');
    const tocHeader = document.querySelector('.toc-header');
    const cfLeft = document.querySelector('.cf-left');
    const articleContainer = document.querySelector('.article-container');
    if (stickyBar !== null && stickyBar.classList.contains('fixed')) {
        const articleWidthCalc = (window.outerWidth(articleContainer) + 27);
        stickyBar.style.width = articleWidthCalc + 'px';
        if (cfLeft !== null && tocHeader !== null) {
            const tocWidthCalc = (window.outerWidth(cfLeft) - 20);
            tocHeader.style.width = tocWidthCalc + 'px';
        }
    } else if (stickyBar !== null && !stickyBar.classList.contains('fixed')) {
        stickyBar.style.width = '';
        if (cfLeft !== null && tocHeader !== null) {
            tocHeader.style.width = '';
        }
    }
}

function formatBytes(bytes, decimals) {

    if (bytes === undefined || bytes === null || bytes === "") return '2 MB';

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatDate(date, contextLanguage = "en-US", formatSpecifier = '') {
    if (date && date !== "") {
        var formattedDate = new Date(date);
        if (formattedDate.getFullYear() < 1000) {
            return "";
        }
        if (formatSpecifier === "shortDate") {
            return (formattedDate.getMonth() + 1) + "-" + formattedDate.getDate() + "-" + formattedDate.getFullYear();
        }else if (formatSpecifier !== "" && ['numeric', '2-digit','short', 'narrow', 'long'].indexOf(formatSpecifier) !== -1) {
            return formattedDate.toLocaleDateString(contextLanguage, { year: 'numeric', month: formatSpecifier, day: 'numeric' });
        }
        return formattedDate.toLocaleDateString(contextLanguage, { year: 'numeric', month: 'long', day: 'numeric' });
    }
    else {
        return "";
    }
}

function getContentItemTitle(contentItem, skipFootNote = false) {
    //Commenting this out for now, as it removes h3s. Saving first line of the function to show parentItem, and then if parentItem === null. Dev to revisit
    //function getContentItemTitle(contentItem, parentItem) {
    //If we are on the first level, then the parentItem is null as we have
    //already written out the current item as the page title, so we do not write a title for this item!*/}
    //if (parentItem === null) {
    //    return "";
    //}

    var contentItemTitle = ((contentItem === null || contentItem.title === null)) ? "" : contentItem.title;
    var contentItemRichTextTitle = ((contentItem === null || contentItem.rich_text_title === null)) ? "" : contentItem.rich_text_title;
    const itemReferenceID = (contentItem?.reference_id) ? contentItem.reference_id : "";
    const itemParaNumber = (contentItem?.para_number) ? contentItem.para_number : "";

    if (itemParaNumber !== "") {
        contentItemTitle = itemParaNumber;
    }
    else {
        contentItemTitle = itemReferenceID + " " + contentItemTitle;
    }

    // Regular expression to test if content is between <fn> and <\/fn>
    const regExpressionToTestIfIncludeFnTag = /(<fn.*>)([\s\S]*)(<\/fn>)/g

    //Check if it contains footnotes
    if (regExpressionToTestIfIncludeFnTag.test(contentItemRichTextTitle)) {
        if(skipFootNote){ //This will remove the footnote if the skip foot note flag is true.
            contentItemRichTextTitle = contentItemRichTextTitle.replace(regExpressionToTestIfIncludeFnTag,"")
        }
        else{
            let footnoteRttArray = getFootnoteDetailsFromRichText(contentItemRichTextTitle);

            if (footnoteRttArray && footnoteRttArray.length > 0) {
                var fnMarkup = createMarkupFootnote(footnoteRttArray[0].number, footnoteRttArray[0].label)

                var el = document.createElement('div');
                el.innerHTML = contentItemRichTextTitle;

                var fnEl = el.querySelector('fn');
                fnEl.parentNode.outerHTML = fnMarkup;

                return el.innerHTML;
            }
        }
    }

    if (contentItemRichTextTitle) { return contentItemRichTextTitle }

    return contentItemTitle.trim().replace(/(\r\n|\n|\r)/gm, " "); //Remove any line breaks - https://www.textfixer.com/tutorials/javascript-line-breaks.php
}

function getContentItemContentAsFlat(contentItem) {
    var list = [];
    list.push(contentItem);
    if (contentItem.content && contentItem.content[0] && contentItem.content[0].subItems) {
        contentItem.content[0].subItems.forEach((item) => getContentItemSubItem(item));
    }

    function getContentItemSubItem(subItem) {
        //TODO: Content does not have the flag, as it swas not added to this object --> !subItem.containsBinary
        if (subItem) {
            list.push(subItem);
            if (subItem.content[0] && subItem.content[0].subItems) {
                subItem.content[0].subItems.forEach((item) => getContentItemSubItem(item));
            }
        }
    }

    return list;
}



function getFootnoteDetailsFromRichText(contentItemRichTextTitle) {
    let footnoteRttArray = [];
    const fnNumberGenerator = ARTICLEHELPERS.footnoteNumberGenerator();

    footnoteRttArray = ARTICLEHELPERS.extractFootnotesFromRTT(contentItemRichTextTitle, fnNumberGenerator);
    return footnoteRttArray;
}

function createMarkupFootnote(key, label) {
    return `<sup class="footnote footnote-click" id="footnote-top-${key}"><a class="footnote-link fn-in-title" data-footnote-num="${key}" href="#footnote-${key}" >${label}</a></sup>`;
}

function getContentItemNavTitle(contentItem) {
    var contentItemTitle = ((contentItem === null || contentItem.title === null)) ? "" : contentItem.nav_title;
    contentItemTitle = (contentItemTitle === '' && contentItemTitle.title !== null && contentItemTitle.title !== '') ? contentItem.title : contentItemTitle;

    const itemReferenceID = (contentItem.reference_id) ? contentItem.reference_id : "";
    const itemParaNumber = (contentItem.para_number) ? contentItem.para_number : "";

    if (itemParaNumber !== "") {
        contentItemTitle = itemParaNumber;
    }
    else {
        contentItemTitle = itemReferenceID + " " + contentItemTitle;
    }

    return contentItemTitle.trim();
}

function isStringNullorEmpty(input) {
    return (!input || input === null || input.trim() === "" || typeof input === 'undefined');
}

function isObjectNullorEmpty(input) {
    return (!input || input === null || typeof input === 'undefined');
}

function isArrayNullorEmpty(input) {
    return (!input || input === null || typeof input === 'undefined' || input?.length === 0 );
}

//TODO:BDP: THese should ALL re-writen/moved to ProfileHelper and use StorageHelper to access storage!!
//import SH from './StorageHelpers';
// Browser Storage Helpers
// Session Storage
function getSessionStorage(key) {
    var sessionStorage = window.sessionStorage;
    var item = sessionStorage.getItem(key);
    return item;
}

function setSessionStorage(key, value) {
    var sessionStorage = window.sessionStorage;
    sessionStorage.setItem(key, value);
}


function removeSessionStorage(key) {
    sessionStorage.removeItem(key);
}

//TODO:BDP: Local Storage --> StorageHelpers!
function getLocalStorage(key) {
    var localStorage = window.localStorage;
    var item = localStorage.getItem(key);
    return item;
}

function setLocalStorage(key, value) {
    var localStorage = window.localStorage;
    localStorage.setItem(key, value);
}

function removeLocalStorage(key){
    localStorage.removeItem(key);
}

function getLocalStorageJSON(key) {
    var localStorage = window.localStorage;
    return JSON.parse(localStorage.getItem(key));
}

function setLocalStorageJSON(key, value) {
    var localStorage = window.localStorage;
    localStorage.setItem(key, JSON.stringify(value));
}

function setMemberFirmLocalStorage(memberFirm) {
    setLocalStorage("research-portal-member-firm", memberFirm);
}

function getMemberFirmLocalStorage() {
    return getLocalStorage("research-portal-member-firm");
}

function setDarkModePrefLocalStorage(bool) {
    const val = bool ? 'true' : '';
    setLocalStorage("research-portal-use-dark-mode", val);
}

function getDarkModePrefLocalStorage() {
    return getLocalStorage("research-portal-use-dark-mode") === 'true';
}

function getIsDebugMTLocalStorage() {
    let isDebug = getLocalStorage('mt-debug');
    return (isDebug === "true");    
}

function setRATakeOverData (context, tier, researchAssistantResponse, memberfirmId) {
    UTILITIESHELPER.setLocalStorage('research-assistant-tier-selected', JSON.stringify(tier));
    UTILITIESHELPER.setLocalStorage('research-assistant-memberfirm', context.memberFirm);
    UTILITIESHELPER.setLocalStorage('research-assistant-member-firm-Id', memberfirmId);
    UTILITIESHELPER.setLocalStorage('research-assistant-language', context.language);
    UTILITIESHELPER.setLocalStorage('research-assistant-knowledgedomain', context.knowledgeDomain);
    UTILITIESHELPER.setLocalStorage('research-assistant-catalog-conversation', researchAssistantResponse);
    let tiers=[];
    tiers= tier[context.memberFirm] && tier[context.memberFirm][context.knowledgeDomain] && tier[context.memberFirm][context.knowledgeDomain].length ? tier[context.memberFirm][context.knowledgeDomain].sort((a, b) => a.id - b.id).map(x => x.value) : [];
    UTILITIESHELPER.setLocalStorage('research-assistant-tier',JSON.stringify(tiers));
    
}

function getCurrentLanguageLocalStorage(urlLanguage) {

    // Retrieve current language selection
    const ssName = getSessionStorage('cur-mt-name');

    // If we have a current language in session storage, return lang info
    if (ssName) {
        const abbreviation = getSessionStorage("cur-mt-abbreviation");
       // const languageCodeMT = getSessionStorage("cur-mt-languageCodeMT");
        let languageCodeFE = getSessionStorage("cur-mt-languageCodeFE");

        if (!languageCodeFE || languageCodeFE === "null" || languageCodeFE === "") {
            languageCodeFE = urlLanguage;
        }

        return { name: ssName, abbreviation, languageCodeFE };
    }

    // Retrieve local storage language configuration
    const lsName = getLocalStorage("mt-name");

    // If we have a language in local storage, return lang info
    if (lsName) {
        const abbreviation = getLocalStorage("mt-abbreviation");
        // const languageCodeMT = getLocalStorage("mt-languageCodeMT");
        let languageCodeFE = getLocalStorage("mt-languageCodeFE");

        if (!languageCodeFE || languageCodeFE === "null" || languageCodeFE === "") {
            languageCodeFE = urlLanguage;
        }

        return { name: lsName, abbreviation, languageCodeFE };
    }

    // Fallback to English
    return { name: "English", abbreviation: "EN",  languageCodeFE: urlLanguage };
}

function annotationDisplayStatus() {
    var annotationCookie = getLocalStorage('article-annotations');
    if (annotationCookie !== null && annotationCookie === 'hidden') {
        return false
    }
    return true;
}

function highlightsDisplayStatus() {
    var highlightsCookie = getLocalStorage('article-highlights');
    if (highlightsCookie !== null && highlightsCookie === 'hidden') {
        return false;
    }
    return true;
}

function commentsDisplayStatus() {
    var commentsCookie = getLocalStorage('article-comments');
    if (commentsCookie !== null && commentsCookie === 'hidden') {
        return false;
    }
    return true;
}

function embeddedDisplayStatus() {
    var embeddedCookie = UTILITIESHELPER.getLocalStorage('article-embedded');
    if (embeddedCookie !== null && embeddedCookie === 'shown') {
        return true;
    }
    return false;
}

function sectionExpandDisplayStatus() {
    var sectionExpandCookie = getLocalStorage('section-expand');
    if (sectionExpandCookie !== null && sectionExpandCookie === 'collapsed') {
        return false;
    }
    return true;
}

function getAuthToken() {
    var token = getAccessToken();
    return token;
}

function getAccessToken()
{
    // first we check if token has expired
    // if not expired
    return sessionStorage.getItem("accesstoken");
    // else 
    //return refreshaccesstoken();
}
function isValidAccessToken() {
    var isValidToken = JSON.parse(sessionStorage.getItem("IsValidToken"));
    return isValidToken;

}
//TODO: Replace the calling of this with the formatDate
function generateFriendlyDate(date) {
    if (date && !date.includes('0001')) {
        if (typeof date === "string") { date = new Date(date); }

        var month = "";

        switch (date?.getMonth()) {
            case 0:
                month = "January";
                break;
            case 1:
                month = "February";
                break;
            case 2:
                month = "March";
                break;
            case 3:
                month = "April";
                break;
            case 4:
                month = "May";
                break;
            case 5:
                month = "June";
                break;
            case 6:
                month = "July";
                break;
            case 7:
                month = "August";
                break;
            case 8:
                month = "September";
                break;
            case 9:
                month = "October";
                break;
            case 10:
                month = "November";
                break;
            case 11:
                month = "December";
                break;
            default:
                break;
        }

        return (month + " " + date?.getDate() + ", " + date?.getFullYear());
    }
    else {
        return "";
    }
}

function getBaseDomain() {
    var baseDomain = window.location.protocol + "//" + window.location.hostname;

    //catch for running locally
    if (window.location.hostname.includes("localhost")) {
        baseDomain = baseDomain + ":" + window.location.port;
    }

    return baseDomain;
}


function generateUUID() {
    // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //     var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    //     return v.toString(16);
    // });
    return crypto.randomUUID();
}

function generateRandomNumber() {  
    const array = new Uint32Array(1);
     window.crypto.getRandomValues(array);//Random number between 0 and 4294967295 will be generated
     return array[0];  
}  

function titleCase(s) {
    if (typeof s !== 'string') return '';
    //return s.replaceAll(/\b[a-z]/g, (letter) => letter.toUpperCase());
    return s.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
}
function capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.replace(/\b[a-z]/, (letter) => letter.toUpperCase());
}
function encodeSpecialCharacters(title) {
    if (!isNullOrUndefined(title)) {
        var actualTitle = title.replace(/%/gi, '%25').replace(/\?/gi, "%3F").replace(/\//gi, '%2F');
        return actualTitle;
    }
    return null;
}

function decodeSpecialCharacters(title) {
    // var actualTitle = title.replaceAll(/&amp;/g, '&')
    var actualTitle = title.replace(/&amp;/g, '&')
        // .replaceAll('%252f', '/')
        // .replaceAll('%252F', '/')
        // .replaceAll('%2f', '/')
        // .replaceAll('%2F', '/');

        .replace(/%252f/g, '/')
        .replace(/%2f/g, '/')
        .replace(/%252F/g, '/')
        .replace(/%2F/g, '/');


    //replaceAll( " ","%20",).replaceAll("!","%21").replaceAll('"',"%22").
    //replaceAll( "#","%23"). replaceAll( "$","%24").replaceAll( "&","%26").
    //    .replaceAll("*","%2A").replaceAll("'", "%27").replaceAll("(","%28")
    //    .replaceAll( ")","%29").replaceAll(";","%3b").replaceAll(":","%3a").replaceAll("@","%40",)
    //    .replaceAll("&","%26").replaceAll("=","%3d",).replaceAll("+","%2b")
    //    .replaceAll( ",","%2c")
    //    .replaceAll( "~", "%7e")
    return actualTitle;
}
function removeNewLineCharacter(text) {
    //return text.replaceAll("\n", "");

    return text.replace(/\n/g, "");
  }


function removeNonBreakingSpaces(content) {
  return content.replace(/&nbsp;/g, "");
}

function truncateText(text, length) {
    return text.length > length ? text.substring(0, length-3) + "..." : text;
}

function removeLargeSpacesFromRichText(text)
{
    return text?.replace(/\s+/g, ' ');
}

function IsTextHavingFnTag(text)
{
    const regExpressionToTestIfIncludeFnTag = new RegExp("(<fn.*>)(.*)(<\/fn>)", "g").test(removeLargeSpacesFromRichText(text)) // eslint-disable-line
    return regExpressionToTestIfIncludeFnTag;
}
//this method checks if the passed value is null or undefined
function isNullOrUndefined(value) {
    //For some reason in the LinkHandler, if (!UTILITIESHELPER.isNullOrUndefined(context.pageHashTag)) {
    //  when undefined, was still being added to the targetIdentifier, so I added the value === undefined!
    if (typeof value === undefined || value === undefined || value === null){
        return true;
    }
        return false;
}

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

//this method replaces all the special characters which are not allowed as file name ex space,dot(.),"<>:"/\\|?*—"
function removeSpecialCharacterFromFileName(fileName) {
    // return fileName.replaceAll(/[ .<>:"/\\|?*+]/g, "_").replaceAll("’", "'");
    return fileName.replace(/[ .<>:"/\\|?*+]/g, '_').replace(/’/g, "'").replace(/&amp;/g, '&');
}

function restrictLengthOfFileName(fileName) {
    let newFileName =  fileName?.length > 97 ? fileName?.substring(0,97) : fileName
     return newFileName;
 }

 function removeSpecialCharacterFromUrlText(url) {
    return url.replace(/[ .<>:"|?*+_]/g, " ");
}

function checkStringIsHtml(str) {
    let regexToCheckIsHtml = /<\/?[a-z][\s\S]*>/i;
    let isHtml = regexToCheckIsHtml.test(str);
    if (!isHtml) {
        return `<span>${str}</span>`
    }
     return str
}

function removeBrackets(inputString)
{
    return inputString.replaceAll('(','').replaceAll(')','').replaceAll('[','').replaceAll(']','');
}

function handleSpaceBarOnSearchSubmit(e) {
    let startPos = e.currentTarget.selectionStart;
    const startString = e.currentTarget.value.substring(0, startPos);
    const endString = e.currentTarget.value.substring(
        startPos,
        e.currentTarget.value.length
    );
    e.currentTarget.value = startString + " " + endString;
    startPos = startPos + 1;
    e.currentTarget.selectionStart = startPos;
    e.currentTarget.selectionEnd = startPos;
    return { startString, endString };
}

function replaceGlobalWithDTTFromPath(pathname) {
    var pathArray = pathname.split('/');
    if (pathArray[1] === MEMBERFIRM_CONSTANTS.global)
        pathArray[1] = MEMBERFIRM_CONSTANTS.dtt;
    return pathArray.join('/');
}

function isHistoryRequest(requestOptions) {
    return JSON.parse(requestOptions.body).section === 'history';
}

function getDCSAccessToken() {
    const IsLocal = (window.location.href.indexOf("localhost") > 0);

    if (IsLocal) {
        return window.DART.ENV.REACT_APP_DCS_CLIENT_ACCESSTOKEN;
    }
    else {
        let msalkeys = sessionStorage.getItem(`msal.token.keys.${window.DART.ENV.REACT_APP_CLIENTID}`);
        let msalaccessToken = JSON.parse(msalkeys)?.accessToken;
        if (!isArrayNullorEmpty(msalaccessToken)) {
            let accessTokenKey = msalaccessToken.find(x => x.includes(`${window.DART.ENV.REACT_APP_CLIENTID}/.default`));
            let accessToken = JSON.parse(sessionStorage.getItem(accessTokenKey))?.secret;
            return accessToken;
        }
        else {
            return getAccessToken();
        }
    }
}

function checkIsProdSite () {
    if (window.DART && window.DART.ENV && window.DART.ENV.RP_REACT_APP_PREVIEW){
        return window.DART.ENV.RP_REACT_APP_PREVIEW !== "true";
    }
    return false;
}

function ReplaceSpecialCharacterInURL(url) {
    url = url.replace(/%20/g, "_");
    url = url.replace(/\[/g, "(");
    url = url.replace(/]/g, ")");
    url = url.replace(/&/g, "n");
    url = url.replace(/%E2%80%94/g, "-").replace(/%e2%80%94/g, "-").replace(/%e2%80%99/g, "'").replace(/%E2%80%99/g, "'").replace(/%e2%80%93/g, "-").replace(/%E2%80%93/g, "-").replace(/%26/g,"n").replace(/%2C/g,",").replace(/%252F/g,"-").replace(/%E2%80%9C/g,'"').replace(/%e2%80%9C/g,'"').replace(/%E2%80%9D/g,'"').replace(/%e2%80%9D/g,'"');//added for -,',"",&,comma
    return url;
}

function ReplaceSpecialCharacterInURLForShareModel(url) {
    url = url.replace(/%20/g, " ");
    url = url.replace(/\[/g, "(");
    url = url.replace(/]/g, ")");
    url = url.replace(/&/g, "n");
    url = url.replace(/%E2%80%94/g, "-").replace(/%e2%80%94/g, "-").replace(/%e2%80%99/g, "'").replace(/%E2%80%99/g, "'").replace(/%e2%80%93/g, "-").replace(/%E2%80%93/g, "-").replace(/%26/g,"n").replace(/%2C/g,",").replace(/%252F/g,"-").replace(/%E2%80%9C/g,'"').replace(/%e2%80%9C/g,'"').replace(/%E2%80%9D/g,'"').replace(/%e2%80%9D/g,'"');//added for -,',"",&,comma
    return url;
}
function CheckIfContainsUnicodes(url) {
    if (url.includes("%") || url.includes("[")
        || url.includes("]") || url.includes("&")) {
        return true;
    }
    return false;
}
function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}
function replaceFootnoteWithLinkInChat(chatDetails) {
    const content = chatDetails?.response?.match(/(?:\[[0-9]{1,}\]){1,}/g)
    let chatDetailsWithFootnote = chatDetails?.response
    let documentSource = chatDetails?.documentSource?.map(document => {
        document.key = generateRandomNumber();
        return document
    })

    content?.length && content.forEach((data, index) => {
        const splitInnerArray = data.match(/\[[0-9]{1,}\]/g)
        splitInnerArray.forEach((footnoteValue, index) => {
            const extractNumber = footnoteValue.match(/\d+/)[0]
            const currentFootnote = documentSource?.find(footnote => (footnote?.footnote?.toString() === extractNumber))
            if(index === 0) {
                const createFootNoteHtml = createMarkupFootnote(currentFootnote?.key || index + 1, extractNumber)
                chatDetailsWithFootnote =  chatDetailsWithFootnote.replace(footnoteValue, createFootNoteHtml)
            } else {
                const createFootNoteHtml = createMarkupFootnote(currentFootnote?.key || index + 1, extractNumber)
                chatDetailsWithFootnote =  chatDetailsWithFootnote.replace(footnoteValue, '<sup class="footnote-link footnote-comma">,</sup>' + createFootNoteHtml)
            }
        })
    })
    return { message: chatDetailsWithFootnote, documentSource }
}

function isResearchAssistantEnabledForKD(kdSettings, country, knowledgeDomain) {
    if (kdSettings && country && knowledgeDomain) {
        return kdSettings?.filter(x => x.country === country)[0]?.knowledgeDomain?.filter(x => x.name === knowledgeDomain)[0]?.siteFeatures?.filter(x => x.name === 'ResearchAssistant')[0]?.Enabled;
    }
}
async function generateHash(htmlString){
    const utf8 = new TextEncoder().encode(htmlString+window.DART.ENV.RP_SECRET_ENCRYPTION_KEY);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}

function getLanguageListByMFandKD(kdSettings, country, knowledgeDomain) {
    if (kdSettings && country && knowledgeDomain) {
        return kdSettings?.filter(x => x.country === country)[0]?.knowledgeDomain?.filter(x => x.name === knowledgeDomain)[0]?.language.split(",").map(lang => (
            {
                language: lang,
                defaultLanguage: kdSettings?.filter(x => x.country === country)[0].defaultLanguage,
                country: kdSettings?.filter(x => x.country === country)[0].country,
                publicationID: kdSettings?.filter(x => x.country === country)[0].publicationID
            }
        ));
    }
}

function getMmeberFirmSettingByTlTaxonomyId(memberFirmSettings, MF_TaxonomyId) {
    if (!UTILITIESHELPER.isArrayNullorEmpty(memberFirmSettings) && !UTILITIESHELPER.isStringNullorEmpty(MF_TaxonomyId)) {
        const filteredItems = memberFirmSettings?.filter(item => item.id === MF_TaxonomyId);
        if (filteredItems.length > 0) {
            return filteredItems[0];
        }
    }
    return null;
}

function removeSpecialCharacterFromTitle(title)
{
    return title.replace(/&amp;/g, '&')
}

function hasExcludedContentType(items) {
    return items.some(item => item.content_type ===  CONTENT_TYPE_IDS.template);
};

const UTILITIESHELPER = {
    stickyBarResizing,
    formatBytes,
    formatDate,
    getContentItemTitle,
    getContentItemNavTitle,
    getLocalStorage,
    setLocalStorage,
    getLocalStorageJSON,
    setLocalStorageJSON,
    annotationDisplayStatus,
    highlightsDisplayStatus,
    commentsDisplayStatus,
    embeddedDisplayStatus,
    sectionExpandDisplayStatus,
    getAuthToken,
    isStringNullorEmpty,
    isObjectNullorEmpty,
    isArrayNullorEmpty,
    setSessionStorage,
    removeSessionStorage,
    getSessionStorage,
    generateFriendlyDate,
    getBaseDomain,
    setMemberFirmLocalStorage,
    getMemberFirmLocalStorage,
    setDarkModePrefLocalStorage,
    getDarkModePrefLocalStorage,
    getCurrentLanguageLocalStorage,
    getIsDebugMTLocalStorage,
    log,
    generateUUID,
    encodeSpecialCharacters,
    decodeSpecialCharacters,
    titleCase,
    capitalize,
    getFootnoteDetailsFromRichText,
    createMarkupFootnote,
    getIntFromStr,
    sortArray,
    getContentItemContentAsFlat,
    sortAlphaNumericArray,
    removeNewLineCharacter,
    removeNonBreakingSpaces,
    truncateText,
    removeLargeSpacesFromRichText,
    IsTextHavingFnTag,
    removeSpecialCharacterFromFileName,
    restrictLengthOfFileName,
    sortAlphaCaseInsensitive,
    isNullOrUndefined,
    checkStringIsHtml,
    handleSpaceBarOnSearchSubmit,
    isValidDate,
    replaceGlobalWithDTTFromPath,
    sortArrayDesc,
    isHistoryRequest,
    getDCSAccessToken,
    dynamicSort,
    dynamicSortDesc,
    removeLocalStorage,
    checkIsProdSite,
    ReplaceSpecialCharacterInURL,
    removeBrackets,
    isEmptyObject,
    isValidAccessToken,
    replaceFootnoteWithLinkInChat,
    setRATakeOverData,
    isResearchAssistantEnabledForKD,
    generateHash,
    removeSpecialCharacterFromUrlText,
    CheckIfContainsUnicodes,
    getLanguageListByMFandKD,
    getMmeberFirmSettingByTlTaxonomyId,
    generateRandomNumber,
    ReplaceSpecialCharacterInURLForShareModel,
    removeSpecialCharacterFromTitle,
    hasExcludedContentType
}

export default UTILITIESHELPER;
