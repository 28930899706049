import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { renderToString } from 'react-dom/server'
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import TocContextProvider, { useTocContext } from '../../contexts/TocContext';
import ShowIf from '../Common/ShowIf';
import PageContextProvider, { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import RenderAllContainer from './RenderAllContainer';
import ArticleContextProvider from '../../contexts/ArticleContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import ReadingModeContextProvider, { useReadingModeContext } from '../../contexts/ReadingModeContext';
import Checkbox from '../Common/Checkbox';
import { useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import AnnotationPanelDropdown from '../Annotations/AnnotationPanelDropdown';
import DOWNLOADHELPERS from '../../helpers/DownloadHelpers';
import { logs, page } from '../../helpers/log';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { TEMPLATE_REPOSITORY_PUBLICATION_TYPES, ARTICLE_DOWNLOAD, PAGE_CONTROLLER_TYPE } from '../Constants/Constants';

export const DownloadPanel = (props) => {

    const location = useLocation();
    const { setDownloadPanelOpen, tier, industry, context, isJournalPage, isFolioPage } = usePageContext();
    const { toc, pubInfo } = useTocContext(); //for generating list of available articles for the manual.
    const selectedDownloadTypeArticle = 'article', selectedDownloadTypePublication = 'publication';
    const [selectedDownloadType, setSelectedDownloadType] = useState("article"); //article download selected by default.
    const [publication, setPublication] = useState({});
    const [foliopublication, setFolioPublication] = useState({});
    const [articleLabels, setArticleLabels] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [downloadClicked, setDownloadClicked] = useState(false);
    const [isBinaryLoaded, setIsBinaryLoaded] = useState(false);
    const [displayBinary, setDisplayBinary] = useState();
    const [showHideBinary, setShowHideBinary] = useState(false);
    const [listToDownload, setListToDownload] = useState([]);//eslint-disable-line
    const [showArticleBinaries, setShowArticleBinaries] = useState(true);
    const { isReadingMode } = useReadingModeContext();
    const articleContent = AddBinariesInArticleContentCopy(props.page.articleContent.props.pubData);
    const [selectedList, setSelectedList] = useState([articleContent]); //initial value include the articleContent, since it'll be auto selected.
    const articleTitleRaw = props.page.articleTitle.replace(/(<([^>]+)>)/ig, '');
    const articleTitle = articleTitleRaw === "" ? props.page.navTitle : articleTitleRaw;
    const publicationTitle = context.pageControllerType === "folio" ? props.page.pubData.title : props.page.navTitle;
    const shouldBeFixed = props.shouldBeFixed;
    const { getLabel } = usePageContextLabels();
    const disableDownloadButtonClassName = 'btn btn-disabled download-panel-button-clicked';
    const enableDownloadButtonClassName = 'btn btn-primary download-panel-button';
    const [downloadButtonClassName, setDownloadButtonClassName] = useState(!downloadClicked ? enableDownloadButtonClassName : disableDownloadButtonClassName);
    const [articleContentCopy, setArticleContentCopy] = useState(cloneDeep(articleContent));//eslint-disable-line
    const { state: annState } = useUserPreferencesContext();
    const [selectedAnnotationType, setSelectedAnnotationType] = useState("viewAll");
    const [selectedAnnotationList, setSelectedAnnotationList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [isAllCategorySelected, setIsAllCategorySelected] = useState(false);
    const [includeAnnotationsChecked, setIncludeAnnotationsChecked] = useState(false);
    const [selectedSpinePubGuidList, setSelectedSpinePubGuidList] = useState([]);
    const { isAnnotationsEnabled, isRestrictedAccessMemberfirm } = usePageContextSettings();
    const [downloadAlert, setDownloadAlert] = useState(false);
    const { guidebookArticle } = useGuidebookTocContext();
    const parentTitleString = "parentTitle";
    const parentGuidString = "parentGuid";
    const downloadProps = {
        eventType: "download",
        pageUrl: window.location.href,
        selectedTocItem: UTILITIESHELPER.getSessionStorage("SelectedContent"),
        pageType: UTILITIESHELPER.getSessionStorage("PageType"),
        jobTitle: UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage("research-portal-member-firm"),
    };
    const templateRepositoryPublicationTypeList = [TEMPLATE_REPOSITORY_PUBLICATION_TYPES.template_repository_account_publicaton_type, TEMPLATE_REPOSITORY_PUBLICATION_TYPES.template_repository_omnia_publicaton_type, TEMPLATE_REPOSITORY_PUBLICATION_TYPES.template_repository_publicaton_type];
    var breadcrumbheight = document.querySelector('.crumbtrail')
    var topicsToAddToDownload = [];
    var publicationContentItems = [];
    const [annnotationCategories, setAnnnotationCategories] = useState(annState?.categories?.filter(item => !item.is_specific_to_bookmark));
    const [panel, setpPanelHeight] = useState(0);
    const RP_Preview_Env = window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE;
    useEffect(() => {
        var panelHeight = 0;
        let crumbtail = document.getElementsByClassName("crumbtrail")[0]?.clientHeight;
        if (!crumbtail) {
            crumbtail = 0;
        }
        let heightOfHorizontalScroll = document.getElementsByClassName("scrollbar-horizontal-container")[0]?.clientHeight;
        if (!heightOfHorizontalScroll) {
            heightOfHorizontalScroll = 0;
        }
        let previewHeight = document.getElementsByClassName("preview-warning")[0]?.clientHeight;

        if (!previewHeight) {
            previewHeight = 0;
        }
        var fixedarticlenav = document.getElementsByClassName("fixed-nav-article")[0]?.clientHeight;
        if (!fixedarticlenav) {
            fixedarticlenav = 0;
        }
        panelHeight = crumbtail + heightOfHorizontalScroll + previewHeight + fixedarticlenav;
        setpPanelHeight(panelHeight);
    }, [shouldBeFixed])

    useEffect(() => {
        const retrieveWholePublication = async () => {
            setIsBinaryLoaded(true);
            var selectedpublication = await RETRIEVALSERVICES.retrieveAllContentsGuidebookPublication();
            if (selectedpublication.content !== null) {
                setPublication(selectedpublication);
                setFolioPublication(selectedpublication);
                setIsBinaryLoaded(false);
            }
        }
        if (isFolioPage) {
            var selectedpublication = {};

            var tocData = toc[context.pageSelectedSection.toString()];
            if (UTILITIESHELPER.isObjectNullorEmpty(tocData)) {
                let tocIndex = toc?.findIndex(f => f.sectionFriendlyPath === context.pageSelectedSection.toString());
                if (tocIndex !== null || tocIndex !== undefined) {
                    var tocData = toc[tocIndex];//pageSelectedPublicationGUID = GUID-3B74B5F2-A465-461C-B3B1-B7FDFBB660ED"

                    let selectedpublication = tocData?.tableOfContents.filter(x => x.id === context.pageSelectedPublicationGUID.toString())[0] ?? null;

                    if (selectedpublication && !templateRepositoryPublicationTypeList.includes(selectedpublication.publication_type)) {
                        // Get GuidebookPublication
                        if (publication?.id !== context.pageSelectedPublicationGUID) {
                            retrieveWholePublication();
                        }
                    }
                }
            }
        }
        else {
            const res = {}
            res.content = toc
            setPublication(res)
        }
    }, [])//eslint-disable-line

    useEffect(() => {
        setAnnnotationCategories(annState?.categories?.filter(item => !item.is_specific_to_bookmark));
    }, [annState?.categories]);

    useEffect(() => {
        const retrieveWholePublication = async () => {
            try {
                if (isFolioPage) {
                    const filteredContent = foliopublication.content.filter(x => selectedSpinePubGuidList.includes(x.id));
                    const newPublication = { ...publication };
                    newPublication.content = filteredContent;
                    setPublication(newPublication);
                }
                else if (context.pageControllerType === PAGE_CONTROLLER_TYPE.standard) {
                    const res = await RETRIEVALSERVICES.retrieveAllContentsStandardPublication();
                    setPublication(res);
                }
                else {
                    const res = await RETRIEVALSERVICES.retrieveAllContentsManual(selectedSpinePubGuidList);
                    setPublication(res);
                }
            } catch (err) {
                logs.error(page.Article, "DownloadPanel", ErrorMessages.retrieveWholePublicationDownload, err, { eventId: ErrorCodes.Article });
                setPublication({});
            }
        }

        if (downloadClicked) {
            setDownloadButtonClassName(disableDownloadButtonClassName);
            setDownloadAlert(false);

            if (selectedDownloadType === "article") {
                downloadCurrentArticle();
            }
            else {
                retrieveWholePublication();
            }
        }
        else {
            setDownloadButtonClassName(enableDownloadButtonClassName);
        }
    }, [downloadClicked])//eslint-disable-line

    useEffect(() => {
        if ((publication.contents !== null && publication.contents) || (publication.content !== null && publication.content)) {
            if (downloadClicked) {
                downloadMultipleArticles()
            }
            else {
                var list = [];

                if (context?.pageControllerType === "folio") {
                    let targetParent = {};
                    if (downloadClicked) { // download button clicked after selection
                        targetParent = publication;
                    }
                    else { // first load for displaying the selection list
                        targetParent.content = publication.content;
                    }

                    targetParent.content.forEach((item) => {
                        if (selectedList.length > 0) {
                            var articleIndex = selectedList.findIndex((selectedItem) => { return (selectedItem.id === item.id && item?.binaries?.length >= 0) });

                            if (articleIndex >= 0) {
                                var currentBinaries = [];
                                item.binaries.forEach((currentBinary) => {
                                    var binaryIndex = selectedList[articleIndex].binaries.findIndex((obj => obj.contentGUID === currentBinary.contentGUID && obj.isSelected));
                                    if (binaryIndex >= 0) {
                                        currentBinary.isSelected = true;
                                    }
                                    currentBinaries.push(currentBinary);
                                });
                                let object = {
                                    title: item.title,
                                    id: item.id,
                                    binaries: currentBinaries,
                                    containsBinary: item.containsBinary,
                                    includeArticleText: selectedList[articleIndex].includeArticleText
                                };
                                list.push(object)
                            }
                            else {
                                //generate an object for that publication consisting of the title and id.
                                let object = {
                                    title: item.title,
                                    id: item.id,
                                    binaries: item.binaries,
                                    containsBinary: item.containsBinary,
                                    includeArticleText: item.includeArticleText
                                };
                                list.push(object)
                            }
                        }
                        else {
                            var object = {
                                title: item.title,
                                id: item.id,
                                binaries: item.binaries,
                                containsBinary: item.containsBinary,
                                includeArticleText: item.includeArticleText
                            };
                            list.push(object)
                        }
                    })
                }
                else if (isJournalPage) {
                    //journal case, not supported, ignore
                }
                else {
                    //manual case
                    let targetParent = toc;
                    //Recursivly add them...
                    list = getChildrenArticles(targetParent.subItems, null)
                }

                setArticleLabels(list);

                //needed for publication download
                if (downloadClicked) {
                    downloadMultipleArticles()
                }
                else {
                    //set the selected list??
                }
            }
        }
    }, [publication]);//eslint-disable-line

    useEffect(() => {

        function SetSelectedBinariesToDownload() {
            var selectedBinariesList = [];
            var tempSelectedSpinePubGuidList = [];
            selectedList.forEach((selectedItem) => {
                if (selectedItem.binaries) {
                    selectedItem.binaries.forEach((currentBinary) => {
                        if (currentBinary.isSelected) {
                            selectedBinariesList.push(currentBinary);
                        }
                    });
                }
                if (tempSelectedSpinePubGuidList.findIndex(a => a === selectedItem.id) === -1) {
                    tempSelectedSpinePubGuidList.push(selectedItem.id);
                }
            });
            setSelectedSpinePubGuidList(tempSelectedSpinePubGuidList);
            // setListToDownload([...selectedBinariesList]);
        }
        SetSelectedBinariesToDownload();
    }, [selectedList])//eslint-disable-line

    useEffect(() => {
        setSelectedList([]);
        setDownloadButtonState();

        if (selectedDownloadType === selectedDownloadTypePublication) {
            setIncludeAnnotationsChecked(false);
        }

    }, [selectedDownloadType])//eslint-disable-line

    function filterAnnotations() {
        var filterAnnotations = annState.annotations;
        if (selectedCategory && selectedCategory.length > 0) { //Filter by Category
            filterAnnotations = filterAnnotations.filter(item => selectedCategory.includes(item.category_guid));
        }
        if (selectedAnnotationType !== "viewAll") {
            if (selectedAnnotationType === "Comments") {
                filterAnnotations = filterAnnotations.filter(item => item.annotation_type === "Comment");
            }
            else if (selectedAnnotationType === "Highlights") {
                filterAnnotations = filterAnnotations.filter(item => item.annotation_type === "Highlight");
            }
        }
        return filterAnnotations;
    }

    useEffect(() => {
        if (includeAnnotationsChecked) {
            setSelectedAnnotationList(() => { return filterAnnotations() });
        }
        else {
            setSelectedAnnotationList([]);
        }
    }, [selectedAnnotationType, selectedCategory, annState, includeAnnotationsChecked]);//eslint-disable-line

    function AddRecursiveSubItem(subItems) {
        var recurseBinaryList = [];
        subItems.forEach((subItem) => {
            if (subItem.containsBinary && !UTILITIESHELPER.isStringNullorEmpty(subItem.content.uri)) {
                let obj = {
                    binaryURI: subItem.content.uri,
                    binaryTitle: UTILITIESHELPER.isStringNullorEmpty(subItem.title) ? subItem.object_title : subItem.title,
                    binaryFormat: subItem.content.format,
                    contentGUID: subItem.id,
                    isSelected: true
                };

                recurseBinaryList.push(obj);
            }
            if (subItem.subItems.length > 0) {
                let subBinaryList = AddRecursiveSubItem(subItem.subItems);
                if (subBinaryList.length > 0)
                    recurseBinaryList.push(...subBinaryList);

            }


        })
        return recurseBinaryList;
    }

    function AddBinariesInArticleContentCopy(articleContent) {
        var tempArticleContent = { ...articleContent }; //cloneDeep(articleContent); {...articlecontent};

        if (tempArticleContent.content.length > 0) {
            tempArticleContent.content[0].includeArticleText = true;
            let tempBinaries = [];
            tempArticleContent.content.forEach((contentItem) => {
                //START//section to read binaries of same level (non sub item level)
                if (contentItem.containsBinary && !UTILITIESHELPER.isStringNullorEmpty(contentItem.content.uri)) {
                    let obj = {
                        binaryURI: contentItem.content.uri,
                        binaryTitle: UTILITIESHELPER.isStringNullorEmpty(contentItem.title) ? contentItem.object_title : contentItem.title,
                        binaryFormat: contentItem.content.format,
                        contentGUID: contentItem.id,
                        isSelected: true
                    };

                    tempBinaries.push(obj);
                }
                //END//section to read binaries of same level (non sub item level)

                if (contentItem.subItems.length > 0) {
                    var tempBinariesRecursive = AddRecursiveSubItem(contentItem.subItems);
                    if (tempBinariesRecursive.length > 0) {
                        tempBinariesRecursive.map(item => tempBinaries.push(item));
                    }
                }
            })
            tempArticleContent.content[0]["binaries"] = tempBinaries;
        }
        return tempArticleContent;
    }

    function formatContentForWordDocument(content) {
        var wordContent = UTILITIESHELPER.removeNonBreakingSpaces(content);
        return wordContent;
    }

    function accessibleCheckboxAll(e) {
        if (e.key === "Enter") {
            handleSelectAllCheckboxClick();
        }
    }

    function accessibleCheckbox(e, item) {
        if (e.key === "Enter") {
            handleCheckboxClick(item);
        }
    }

    function addArticleToSelectedList(item) {
        var newArray = [];
        var binaryArray = [];

        if (selectedDownloadType === selectedDownloadTypePublication) {
            //case when we already have some item selected
            selectedList.forEach((selectedItem) => {
                newArray.push(selectedItem);
            })
        }

        //add requested/selected item to selected list
        item.includeArticleText = true;
        newArray.push(item);

        //identify all the binaries of the checked articled and create single list which we display within article
        if (item?.binaries?.length > 0) {
            binaryArray = item.binaries;
            binaryArray.forEach((child) => {
                child.isSelected = true;
            });
        }

        //On parent item check, it should check its child item till last(nth) level
        const selectRecursively = (childItem) => {
            childItem.forEach((child) => {
                newArray.push(child)
                if (child?.children?.length) {
                    selectRecursively(child.children);
                }
            });

        }

        if (item?.children?.length) {
            selectRecursively(item.children);
        }

        if (newArray.length > 0) {
            setDownloadButtonClassName(enableDownloadButtonClassName);
        }
        else {
            setDownloadButtonClassName(disableDownloadButtonClassName);
        }

        setSelectedList(newArray);
    }

    function checkAllArticles(subItems, children) {
        for (let child of subItems) {
            child.includeArticleText = true; //when select all true all binaries are also set selected true
            if (child.binaries && child.binaries.length > 0) {
                child.binaries.forEach((currentBinary) => {
                    currentBinary.isSelected = true;
                });
            }
            children.push(child)
            if (child.children && Array.isArray(child.children) && child.children.length > 0) {
                checkAllArticles(child.children, children)
            }
        }

        return children
    }

    function createHtmlBodyArticle() {
        //For single article download.
        let elements = document.querySelectorAll('li > .indent');
        if (elements !== undefined) {
            for (let element in Object.entries(elements)) {
                elements[element].setAttribute("class", "indent");
            }
        }
        //While downloading the Article , in the footnote inside the class-endnote-text whenever we have a <ul>
        // then the Aspose is adding an extra space to the next footnote number , inorder to not let aspose add spaces
        // we have added an extra <p> tag towards the end of the <ul> inside the class-endnote-text
        var endnoteTextElements = document.querySelectorAll('.endnote-text');
        endnoteTextElements.forEach(element => {
            var ulElement = element.querySelector('ul');
            if (ulElement) {
                var pElement = document.createElement('p');
                pElement.textContent = '';
                element.appendChild(pElement);
            }
        });
        var articleContainer = document.getElementsByClassName("article-container")[0];
        var clone = articleContainer.cloneNode(true);
        if (clone.querySelector('.article-title-reading-mode')) {
            clone.children[0].outerHTML = '<h2 class="article-title" style="white-space: normal;">' + clone.children[0].innerHTML + '</h2>';
            clone.children[0].setAttribute("style", "margin: 0px 0px 0px -70px; box-sizing: border-box");
            clone.setAttribute("style", "padding: 20px 0 0 70px; box-sizing: border-box");
        }
        //remove divs for web app modals etc. that we don't want to include in the word doc.
        var toolbardivs = clone.getElementsByClassName('toolbar');
        while (toolbardivs.length > 0) {
            toolbardivs[0].parentNode.removeChild(toolbardivs[0]);
        }

        var fixedToolsDiv = clone.getElementsByClassName('fixed-tools-container');
        while (fixedToolsDiv.length > 0) {
            fixedToolsDiv[0].parentNode.removeChild(fixedToolsDiv[0]);
        }

        //Not including "Top of Article" text from word doc.
        var fixedToolsDivBottom = clone.getElementsByClassName('fixed-tools-container-bottom');
        while (fixedToolsDivBottom.length > 0) {
            fixedToolsDivBottom[0].parentNode.removeChild(fixedToolsDivBottom[0]);
        }

        var modaldivs = clone.getElementsByClassName('modal');
        while (modaldivs.length > 0) {
            modaldivs[0].parentNode.removeChild(modaldivs[0]);
        }

        var nextPrevDivs = clone.getElementsByClassName('prev-next-article-links-container');
        while (nextPrevDivs.length > 0) {
            nextPrevDivs[0].parentNode.removeChild(nextPrevDivs[0]);
        }

        var shareModaldivs = clone.getElementsByClassName('share-modal');
        while (shareModaldivs.length > 0) {
            shareModaldivs[0].parentNode.removeChild(shareModaldivs[0]);
        }

        var miscImagesDivs = clone.getElementsByClassName('icon-file-component');
        while (miscImagesDivs.length > 0) {
            miscImagesDivs[0].parentNode.removeChild(miscImagesDivs[0]);
        }

        var downloadPanelDivs = clone.getElementsByClassName('download-panel');
        while (downloadPanelDivs.length > 0) {
            downloadPanelDivs[0].parentNode.removeChild(downloadPanelDivs[0]);
        }

        //remove notifications from doc
        var notificationDivs = clone.getElementsByClassName('notification');
        while (notificationDivs.length > 0) {
            notificationDivs[0].parentNode.removeChild(notificationDivs[0]);
        }

        //This logic is to remove PDF content in word document
        while (clone.querySelectorAll('.binary-block .pdf').length > 0) {
            const parentElement = clone.querySelectorAll('.binary-block .pdf')[0].parentNode;
            if (parentElement) {
                const parent = parentElement.parentNode;
                if (parent) {
                    parent.remove();
                }
            }
        }

        //this method is specific for case when downloading article that contains PDF viewer in it.
        //and we dont want that PDF Viewer to show up in word docs
        while (clone.querySelectorAll('.binary-block .d-flex').length > 0) {
            clone.querySelectorAll('.binary-block .d-flex')[0].remove();
        }



        DOWNLOADHELPERS.replaceDivWithHeaderTag(clone, 1, false)

        //Generate the article
        let date = new Date();
        const dateValue = UTILITIESHELPER.formatDate(date, context?.language);

        //removing divs with this class, because this div is including unwanted new line within same paragraph.
        var footnotedivs = clone.getElementsByClassName('footnote-popup');
        while (footnotedivs.length > 0) {
            footnotedivs[0].parentNode.removeChild(footnotedivs[0]);
        }

        //remove annotation
        //get all ids which are in selectedAnnotation
        //remove annotation which doesnt include in Annotationlist
        let removalAnnotationIds = [];
        removalAnnotationIds = annState.annotations.filter(function (e) {
            return selectedAnnotationList.indexOf(e) === -1
        });

        removalAnnotationIds = removalAnnotationIds.map(a => a.annotation_guid);
        var markTagg = clone.querySelectorAll(".highlighted");
        for (let i = 0; i < markTagg.length; i++) {
            var markid = removalAnnotationIds.includes(markTagg[i].id);
            if (markid) {
                markTagg[i].parentNode.replaceChild(document.createTextNode(markTagg[i].innerHTML), markTagg[i]);
                let spanid = `#comment${markTagg[i].id}`;
                let spanTag = clone.querySelector(spanid);
                if (spanTag) {
                    spanTag.remove();
                }
            }
        }
        generateAndResolveLinks(clone);
        const linkUrl = UTILITIESHELPER.getBaseDomain() + location.pathname;
        const startString = "<html><head><link type='text/css' rel='Stylesheet' href='" + UTILITIESHELPER.getBaseDomain() + "/css/word.css' /><link type='text/css' rel='Stylesheet' href='" + UTILITIESHELPER.getBaseDomain() + "/css/google-fonts.css' /></head><body>"
        const citationString = `${getAppliedFilterMessage()}<div><p>Link: <a href="${linkUrl}">${publicationTitle}</a></p></div><div><p>${getLabel("b_BinaryDownloadedOn", "Downloaded on:")} ${dateValue}</p></div>`;
        var wordString = formatContentForWordDocument(clone.outerHTML);
        return startString + citationString + wordString + "</body></html>";
    }
    function ResolveLinks(clone) {
        let anchors = clone.getElementsByTagName('a');
        if (anchors.length > 0) {
            Array.from(anchors).forEach(a => {
                const hrefArray = a.href.split("/");
                const href = hrefArray[hrefArray.length - 1];
                const anchorURL = hrefArray[0].concat("//").concat(hrefArray[2]);
                if (href.includes("mailto")) {
                    a.href = href;
                } else if (anchorURL === UTILITIESHELPER.getBaseDomain()) {
                    a.href = `${UTILITIESHELPER.getBaseDomain()}/content/docid=${href}`;
                }
                else {
                    a.href = a.href;
                }
            });
        }
    }
    function generateAndResolveLinks(clone) {
        if (RP_Preview_Env.toLowerCase() === "true") {
            ResolveLinks(clone);
        }
        else {
            let anchors = clone.getElementsByTagName('a');
            if (anchors.length > 0) {
                Array.from(anchors).forEach(a => {
                    if (!(decodeURI(context.pageSelectedPublicationTitle)?.toLowerCase()).includes('technically speaking')) {
                        const spanTag = document.createElement('span');
                        spanTag.title = a.href;
                        spanTag.innerHTML = a.innerHTML;
                        var uTagSingleDownload = spanTag.querySelector('span u');
                        if (uTagSingleDownload != null) {
                            var textNode = document.createTextNode(uTagSingleDownload.textContent);
                            uTagSingleDownload.parentNode.replaceChild(textNode, uTagSingleDownload);
                        }
                        a.parentNode.replaceChild(spanTag, a);
                    }
                    else {
                        //Added if a tag contains images
                        if (a.childNodes[0]?.tagName?.toLowerCase() === 'img') {
                            let imgNodes = a.childNodes[0];
                            imgNodes.className = a.className;
                            imgNodes.title = "TSHREFIMAGE"
                        }
                    }
                });
            }
        }
    }
    function createHtmlBodyPublication(wordString) {
        if (!wordString) {
            return null;
        }
        if (RP_Preview_Env.toLowerCase() === "false") {
            wordString = wordString.replaceAll("<a", "<span").replaceAll("</a>", "</span>");
        }
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(wordString, 'text/html');
        //While downloading the Article , in the footnote inside the class-endnote-text whenever we have a <ul>
        // then the Aspose is adding an extra space to the next footnote number , inorder to not let aspose add spaces
        // we have added an extra <p> tag towards the end of the <ul> inside the class-endnote-text
        var endnoteTextElements = htmlDoc.querySelectorAll('.endnote-text');
        endnoteTextElements.forEach(element => {
            var ulElement = element.querySelector('ul');
            if (ulElement) {
                var pElement = htmlDoc.createElement('p');
                pElement.textContent = '';
                element.appendChild(pElement);
            }
        });

        //Get all div with p in endnote-table-container
        var endNotesContainers = htmlDoc.getElementsByClassName("endnote-table-container");

        //remove duplicate industry tag
        htmlDoc.querySelectorAll('.applicability-tag').forEach(function (a) {
            a.remove()
        })

        //this method is specific for case when downloading article that contains PDF viewer in it.
        //and we dont want that PDF Viewer to show up in word docs
        //clone.querySelectorAll('.binary-block .pdf')
        // var pdfViewerblocks = htmlDoc.querySelectorAll('.binary-block .pdf');
        while (htmlDoc.querySelectorAll('.binary-block .pdf').length > 0) {
            htmlDoc.querySelectorAll('.binary-block .pdf')[0].remove();
        }

        //this method is specific for case when downloading article that contains PDF viewer in it.
        //and we dont want that PDF Viewer to show up in word docs
        while (htmlDoc.querySelectorAll('.binary-block .d-flex').length > 0) {
            htmlDoc.querySelectorAll('.binary-block .d-flex')[0].remove();
        }

        //Convert all subtopic div to h tags
        var articleContainers = htmlDoc.getElementsByClassName("structured-article");
        for (let i = 0; i < articleContainers.length; i++) {
            DOWNLOADHELPERS.replaceDivWithHeaderTag(articleContainers[i], 2, true)
        };

        if (endNotesContainers.length > 0) {
            //this for loop will arrage the notes in the order which is working(having proper rows and containers)
            for (let i = 0; i < endNotesContainers.length; i++) {
                var spansOfEndnoteText = endNotesContainers[i].querySelectorAll("p .endnote-text");
                let counterForCurrentUpdation = 0;

                //class 'p' in the endnote-table-container
                var endnoteTextDivs = endNotesContainers[i].querySelectorAll(".p");
                for (let j = 0; j < spansOfEndnoteText.length && counterForCurrentUpdation < endnoteTextDivs.length; j++) {

                    let currentDiv = endnoteTextDivs[counterForCurrentUpdation];
                    //Paste value only when empty, because sometimes spans contain text directly inside them
                    //as they don't have div pushed inside them
                    if (spansOfEndnoteText[j].innerHTML === '') {
                        //Paste the value in the span with white space prefix(margins are not working in docx)
                        spansOfEndnoteText[j].innerHTML = "  " + currentDiv.innerHTML;
                        //Nullify the div, can't delete because of counter running logic
                        currentDiv.innerHTML = '';
                        //Update only when update span is done, so that no div remains manipulated
                        counterForCurrentUpdation++;
                    }
                    else {
                        //if text already there then just prefix white space
                        spansOfEndnoteText[j].prepend("  ");
                    }
                }
            }
        }
        var footnotedivsnew = htmlDoc.getElementsByClassName('footnote-popup');
        while (footnotedivsnew.length > 0) {
            footnotedivsnew[0].parentNode.removeChild(footnotedivsnew[0]);
        }
        let footnotedivs = htmlDoc.getElementsByClassName('footnote-popup');
        for (let element in Object.entries(footnotedivs)) {
            if (footnotedivs[element] !== undefined) {
                if (footnotedivs[element].parentNode !== undefined) {
                    footnotedivs[element].parentNode.removeChild(footnotedivs[element]);
                }
            }
        }
        if (RP_Preview_Env.toLowerCase() === "true") {
            ResolveLinks(htmlDoc);
        }
        let date = new Date();
        const dateValue = UTILITIESHELPER.formatDate(date, context?.language);
        const linkUrl = UTILITIESHELPER.getBaseDomain() + location.pathname;
        const startString = "<html><head><link type='text/css' rel='Stylesheet' href='" + UTILITIESHELPER.getBaseDomain() + "/css/word.css' /><link type='text/css' rel='Stylesheet' href='" + UTILITIESHELPER.getBaseDomain() + "/css/google-fonts.css' /></head><body>"
        const citationString = `<div><p>Link: <a href="${linkUrl}">${publicationTitle}</a></p></div><div><p>${getLabel("b_BinaryDownloadedOn", "Downloaded on:")} ${dateValue}</p></div>`;
        const body = startString + citationString + htmlDoc.documentElement.outerHTML + "</body></html>";
        return body;
    }

    function displayBinaryHadler(pid) {
        setDisplayBinary(pid)
        setShowHideBinary(prevVal => !prevVal)
    }

    const downloadCurrentArticle = async () => {
        const articleTitle = isReadingMode ? DOWNLOADHELPERS.getHeaderTitleNameInReadingName() : document.querySelector('h2.article-title')?.textContent;
        var trimmedTitle = articleTitle.trim().replace('\n', '').replace(/ +/g, '_');
        var fileName = unescape(trimmedTitle).replace(' ', '-');
        fileName = fileName.replace(/\u2013|\u2014/g, "-");
        //eslint-disable-next-line
        fileName = fileName.replace(/[^\x00-\x7F]/g, ""); //handle any non-ascii chars in the filename
        fileName = UTILITIESHELPER.restrictLengthOfFileName(fileName);
        fileName = UTILITIESHELPER.removeLargeSpacesFromRichText(fileName);
        fileName = UTILITIESHELPER.removeSpecialCharacterFromFileName(fileName);// handle ("." and "/") iin the file name
        var annContent = [];
        var annComment = [];
        if (selectedAnnotationList && selectedAnnotationList.length > 0) {
            selectedAnnotationList.forEach((annText) => {
                if (annText.comment !== undefined) {
                    annContent.push(UTILITIESHELPER.removeNewLineCharacter(annText.content));
                    annComment.push(annText.comment);
                }
            });
        }

        var body = createHtmlBodyArticle();
        var htmlPackage = {
            "PageUrl": window.location.href,
            "FileName": fileName,
            "HtmlBody": body,
            "content": annContent,
            "comment": annComment
        }
        //AppInsights
        downloadProps.documentName = UTILITIESHELPER.getSessionStorage("SelectedContent");
        downloadProps.parentCollectionName = publicationTitle;
        logs.event('download-current', downloadProps)

        //TODO: refactor this later. It handles making sure that we only send what's on the ARTICLE selections rather than both article and publication selections
        //var flatContentItemList = UTILITIESHELPER.getContentItemContentAsFlat(articleContentCopy); //eslint-disable-line
        var binaryRequest = DOWNLOADHELPERS.getBinaryList(selectedList);
        if (binaryRequest.length === 0) { //Article text only usecase, no binaries selected
            try {
                let response = await RETRIEVALSERVICES.convertWord(htmlPackage);
                if (response) {
                    showSearchbar();
                    setSelectedList([])
                    setSelectedList([...selectedList])
                    setDownloadClicked(false);
                    setDownloadPanelOpen(false);
                    setSelectedDownloadType("");
                }
            }
            catch (err) {
                logs.error(page.Article, "DownloadPanel", ErrorMessages.downloadWord, err, { eventId: ErrorCodes.Article });
            }
        }
        else if (binaryRequest.length === 1 && !articleContentCopy?.content[0]?.includeArticleText) { //Single file usecase, there's only 1 binary in the request and the articleText is not selected
            try {
                let response = await RETRIEVALSERVICES.requestFile(binaryRequest[0].fileUri, binaryRequest[0].fileName);
                if (response) {
                    showSearchbar();
                    setSelectedList([])
                    setSelectedList([...selectedList])
                    setDownloadClicked(false);
                    setDownloadPanelOpen(false);
                    setSelectedDownloadType("");
                }
            }
            catch (err) {
                logs.error(page.Article, "DownloadPanel", ErrorMessages.downloadBinary, err, { eventId: ErrorCodes.Article });
            }
        }
        else { //zip download usecase
            htmlPackage.FileName = fileName.replace(".docx", ".zip").replaceAll('.', '_');

            //Below method helps to avoid the conflict when two or more binary files having same name, for more detail refer this defect #2911193
            DOWNLOADHELPERS.renameDuplicateFileNames(binaryRequest, htmlPackage.FileName);
            try {
                let response = await RETRIEVALSERVICES.requestZip(htmlPackage, binaryRequest, fileName);
                if (response) {
                    showSearchbar();
                    setSelectedList([])
                    setSelectedList([...selectedList])
                    setDownloadClicked(false);
                    setDownloadPanelOpen(false);
                    setSelectedDownloadType("");
                }
            }
            catch (err) {
                logs.error(page.Article, "DownloadPanel", ErrorMessages.downloadZip, err, { eventId: ErrorCodes.Article });
            }
        }
    }

    const downloadMultipleArticles = async () => {
        //Get currently checked checkboxes
        //do nothing if no articles are selected.
        if (selectedList.length > 0) {
            //Make a list of the actual content items from the leaf-nodes of the selection
            topicsToAddToDownload = [];
            publicationContentItems = [];

            //Loop through the FLAT list of selected items, and add the items that are leaf-nodes to the list of ID's to find in the content (DATA)
            selectedList.forEach((item) => {
                //ignore items with children, they are listings pages and wont be downloaded.
                if ((!item.children || !item.children?.length > 0) && item.includeArticleText === true) {
                    topicsToAddToDownload.push(item.id);
                }
                if (item.children?.length > 0 || item.children) {
                    recurseSelectedChildren(item);
                }
            });
            //add the topicsToAddToDownload to the publicationContentItems (data)
            publication.content.forEach((item) => {
                recurseSelectedContentItem(item);
            })
            var publicationDuplicate = cloneDeep(publication);
            var renderedArticles = []
            //Convert the content to html
            publicationContentItems.forEach((item) => {
                //The RenderAllContainer expects each article to be alone within a publication object.
                //TODO:Anand --> Guidance is also manual-type - so why are we doing it as if it was a folio (SPine-publications?) [this was in development branch, but asking again here] - is it perhaps section in a folio?!
                if (context?.pageControllerType === "folio" || context?.pageSelectedSection === "guidance") {
                    publicationDuplicate.content = [item]
                }
                else {
                    publicationDuplicate.content = (Array.isArray(item.subItems) && item.subItems.length > 0) ? item.subItems : [item];
                }

                //this method returns section headings when parent section item is selected from the download panel
                var selectedSectionTitle = DOWNLOADHELPERS.getSectionTitle(item, selectedSpinePubGuidList);
                var articleContainerDiv = "<div class='article-container multiple-download'>"
                //put the component into a router so that we can access the contexts for all subcomponents and locations
                //for useLocations, then render the component into a string.
                renderedArticles.push(
                    articleContainerDiv +
                    selectedSectionTitle +
                    "<h1>" + item.content.rich_text_title + "</h1><div class='article-content-container'>" +
                    renderToString(
                        <Router location={location}>
                            <ArticleContextProvider>
                                <PageContextProvider>
                                    <TocContextProvider pubInfo={pubInfo}>
                                        <ReadingModeContextProvider>
                                            <RenderAllContainer pubData={publicationDuplicate} excludeBinaries={true} />
                                        </ReadingModeContextProvider>
                                    </TocContextProvider>
                                </PageContextProvider>
                            </ArticleContextProvider>
                        </Router >
                    )
                    + "</div></div>" + (UTILITIESHELPER.isStringNullorEmpty(selectedSectionTitle) ? "" : "</div></div>")
                )
            })
       let Articles= renderedArticles.map((article,idx)=>{
            let allHrefs=article.match(/(href="([^"]+)")/g);
            let linksModifiedArticle=article;
            if(!UTILITIESHELPER.isArrayNullorEmpty(allHrefs)){
            allHrefs.forEach((link)=>{
                let spiltedLink=link?.split('=')[1].replace(/['"]+/g, '');
                if(spiltedLink.startsWith('#')==true){
                    linksModifiedArticle=linksModifiedArticle.replace(new RegExp(`(href="(${spiltedLink})")`), `href="${topicsToAddToDownload[idx]+spiltedLink}"`);;
            }
            })
        }
            return linksModifiedArticle;
            })
            //stitch together into a single string
            var wordString = Articles.join('');
            if (new Blob([wordString]).size > ARTICLE_DOWNLOAD.SIZE_LIMIT) {
                setDownloadClicked(false);
                setDownloadButtonClassName(enableDownloadButtonClassName);
                setDownloadAlert(true);
                return;
            }
            var body = createHtmlBodyPublication(wordString);
            body = body?.replaceAll(/&nbsp;/gi, " ");
            var trimmedTitle = publicationTitle.trim();
            var fileName = unescape(trimmedTitle)
            //eslint-disable-next-line
            fileName = fileName.replace(/\u2013|\u2014/g, "-").replace(/[^\x00-\x7F]/g, ""); //handle any non-ascii chars in the filename
            fileName = UTILITIESHELPER.removeLargeSpacesFromRichText(fileName);
            fileName = UTILITIESHELPER.restrictLengthOfFileName(fileName);
            fileName = UTILITIESHELPER.removeSpecialCharacterFromFileName(fileName);// handle ("." and "/") iin the file name
            //sort out the pageUrl
            //All cases of pageUrl is already handled in PageContext.jsso we can directly use context.pageBaseURL here
            var pageUrl = `${UTILITIESHELPER.getBaseDomain()}${context.pageBaseURL}`;

            if (UTILITIESHELPER.isObjectNullorEmpty(publicationContentItems)) {
                body = null;
            }
            var htmlPackage = {
                "PageUrl": pageUrl,
                "FileName": UTILITIESHELPER.restrictLengthOfFileName(fileName),
                "HtmlBody": body
            }
            //AppInsights
            var multipleDocuments = [];
            selectedList.forEach(element => {
                multipleDocuments.push('[' + element.title + ']');
            });

            downloadProps.documentName = multipleDocuments.join('# ');
            downloadProps.parentCollectionName = publicationTitle;
            logs.event('download-multiple', downloadProps)

            var binaryRequest = DOWNLOADHELPERS.getBinaryList(selectedList);

            if (binaryRequest.length === 0) { //article case
                try {
                    let response = await RETRIEVALSERVICES.convertWord(htmlPackage);
                    if (response) {
                        showSearchbar();
                        setSelectedList([])
                        setSelectedList([...selectedList])
                        setDownloadClicked(false);
                        setDownloadPanelOpen(false);
                        setSelectedDownloadType("");
                    }
                }
                catch (err) {
                    logs.error(page.Article, "DownloadPanel", ErrorMessages.downloadWord, err, { eventId: ErrorCodes.Article });
                    showSearchbar();
                    setSelectedList([])
                    setSelectedList([...selectedList])
                    setDownloadClicked(false);
                    setDownloadPanelOpen(false);
                    setSelectedDownloadType("");
                }
            }
            else if (selectedList.length === 1 && !selectedList[0].includeArticleText && binaryRequest.length === 1) { //single binary case
                try {
                    let response = await RETRIEVALSERVICES.requestFile(binaryRequest[0].fileUri, binaryRequest[0].fileName);
                    if (response) {
                        showSearchbar();
                        setSelectedList([])
                        setSelectedList([...selectedList])
                        setDownloadClicked(false);
                        setDownloadPanelOpen(false);
                        setSelectedDownloadType("");
                    }
                }
                catch (err) {
                    logs.error(page.Article, "DownloadPanel", ErrorMessages.downloadBinary, err, { eventId: ErrorCodes.Article });
                    showSearchbar();
                    setSelectedList([])
                    setSelectedList([...selectedList])
                    setDownloadClicked(false);
                    setDownloadPanelOpen(false);
                    setSelectedDownloadType("");
                }
            }
            else { //zip case // FileName:"RP-FTEO-Buffet-IA"
                //Below method helps to avoid the conflict when two or more binary files having same name, for more detail refer this defect #2911193
                DOWNLOADHELPERS.renameDuplicateFileNames(binaryRequest);

                try {
                    let response = await RETRIEVALSERVICES.requestZip(htmlPackage, binaryRequest, fileName);
                    if (response) {
                        showSearchbar();
                        setSelectedList([])
                        setSelectedList([...selectedList])
                        setDownloadClicked(false);
                        setDownloadPanelOpen(false);
                        setSelectedDownloadType("");
                    }
                }
                catch (err) {
                    logs.error(page.Article, "DownloadPanel", ErrorMessages.downloadWord, err, { eventId: ErrorCodes.Article });
                    showSearchbar();
                    setSelectedList([])
                    setSelectedList([...selectedList])
                    setDownloadClicked(false);
                    setDownloadPanelOpen(false);
                    setSelectedDownloadType("");
                }
            }
        }
    }

    function generateLabelCheckboxes(children, level) {
        //For some reason, the ShowIf was not working and would send in the undefined/empty object here, so I changed it to an if
        if (!children || children.length === 0) {
            return;
        }

        //Styling the checkboxContainer
        const marginLeftStyling = {
            marginLeft: `${20 * level}px`
        }

        return (
            <>
                {children.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <div className="checkbox-container-download-panel checkbox-container-download-panel-child" style={marginLeftStyling}>
                                <Checkbox
                                    key={item.id + "item"}
                                    classNameCheckmark="checkmark-download-panel"
                                    label={item.title}
                                    isChecked={isArticleSelected(item.id)}
                                    onChange={() => handleCheckboxClick(item)}
                                    disabled={selectedDownloadType !== "publication"}
                                    onKeyDown={(e) => accessibleCheckbox(e, item)}
                                />
                            </div>
                            {(item.children && item.children?.length > 0) ? generateLabelCheckboxes(item.children, level + 1) : ""}
                        </div>
                    )
                })}
            </>
        )
    }

    function getAppliedFilterMessage() {
        let industries = (industry[context?.memberFirm]?.accounting?.length > 0 ? (industry[context?.memberFirm].accounting.map(a => a.name).join(", ")) : "") + (industry[context?.memberFirm]?.auditing?.length > 0 ? (industry[context?.memberFirm].auditing?.map(a => a.name).join(", ")) : "");
        let framework = (tier[context?.memberFirm]?.accounting?.length > 0 ? (tier[context?.memberFirm].accounting.map(a => a.name).join(", ")) : "") + (tier[context?.memberFirm]?.auditing?.length > 0 ? (tier[context?.memberFirm].auditing?.map(a => a.name).join(", ")) : "");

        if (industries !== "" || framework !== "") {
            return `<div><p>${getLabel("b_FiltersApplied", "Filters applied:")} ${industries}${(framework !== "" && industries !== "") ? ", " : ""}${framework}</a></p></div>`;
        }
        return "";
    }

    function getChildrenArticles(subItems, parentId) {
        const children = []
        if (subItems?.length > 0){
            for (let child of subItems) {
                if (!child.containsBinary) {
                    children.push({
                        id: child.id,
                        title: child.title,
                        parentId: parentId,
                        children: child.subItems && Array.isArray(child.subItems) && child.subItems.length > 0 ? getChildrenArticles(child.subItems, child.id) : []
                    });
                }
            }
        }

        return children
    }

    function getUniqueFormat(binaries) {
        if (UTILITIESHELPER.isObjectNullorEmpty(binaries)) {
            return "";
        }
        var formatArr = binaries.map((b) => {
            return (b.binaryFormat.toUpperCase().replace('.', ''))
        });
        var uniqueArray = [...new Set(formatArr)];
        var msg = uniqueArray.join(",");
        var finalMsg = UTILITIESHELPER.isStringNullorEmpty(msg) ? "" : "(" + msg + ")";

        return finalMsg;
    }

    function handleBinaryCheckboxForArticleOnlyClick(parentItem, binaryItem) {
        var newList = selectedList;

        var articleIndex = newList.findIndex((obj => obj.id === parentItem.id))//find index of article in selectedList

        var selectedBinaryIndex = newList[articleIndex].binaries.findIndex((obj => obj.contentGUID === binaryItem.contentGUID));
        if (selectedBinaryIndex >= 0) {
            if (newList[articleIndex].binaries[selectedBinaryIndex].isSelected === true) {
                newList[articleIndex].binaries[selectedBinaryIndex].isSelected = false;
                if (newList[articleIndex].binaries.findIndex((obj => obj.isSelected === true)) < 0 && newList[articleIndex].includeArticleText === false)
                    setDownloadButtonClassName(disableDownloadButtonClassName);
                else
                    setDownloadButtonClassName(enableDownloadButtonClassName);
            }
            else {
                newList[articleIndex].binaries[selectedBinaryIndex].isSelected = true;
                setDownloadButtonClassName(enableDownloadButtonClassName);
            }

            setSelectedList([...newList]);
        }
    }

    function handleBinaryCheckboxClick(parentItem, binaryItem) {
        if (selectedDownloadTypeArticle) {
            //Article Case
            binaryItem.isSelected = !binaryItem.isSelected;

            //Force a refresh
            let articleLableArray = [...articleLabels];
            setArticleLabels([]);
            setArticleLabels([...articleLableArray]);
        }
        else {
            //Publication Case
            var articleIndex = selectedList.findIndex((obj => obj.id === parentItem.id))
            var finalSelected = false;
            if (articleIndex >= 0) {
                const objIndex = selectedList[articleIndex].binaries?.findIndex((obj => obj.contentGUID === binaryItem.contentGUID));
                if (objIndex >= 0 && selectedList[articleIndex].binaries[objIndex].isSelected) {
                    selectedList[articleIndex].binaries[objIndex].isSelected = false
                    finalSelected = false;
                    //case 1: when all binaries are not selected and article text option is also request to deselect
                    if ((selectedList[articleIndex].binaries.findIndex((obj => obj.isSelected === true)) < 0) && parentItem.includeArticleText === false) {
                        //case when binary selected and unchecked, will check if no binary selected the set parent to deselect
                        removeArticleFromSelectedList(parentItem);
                    }
                }
                else {
                    selectedList[articleIndex].binaries[objIndex].isSelected = true;
                    finalSelected = true;
                    setSelectedList([...selectedList]);
                }
            }

            let articleLableArray = [...articleLabels];
            var selectedIndex = articleLableArray?.findIndex((obj => obj.id === parentItem.id));
            if (selectedIndex >= 0) {
                var binaryIndex = articleLableArray[selectedIndex].binaries.findIndex((obj => obj.contentGUID === binaryItem.contentGUID));
                if (binaryIndex >= 0) {
                    articleLableArray[selectedIndex].binaries[binaryIndex].isSelected = finalSelected;
                    setArticleLabels([]);
                    setArticleLabels([...articleLableArray]);
                }
            }
        }
    }

    function handleCheckboxClick(item) {
        if (item && item.id && isArticleSelected(item.id)) {
            removeArticleFromSelectedList(item);
            setSelectAllChecked(false);
        }
        else {
            addArticleToSelectedList(item);
        }
    }

    function handleIncludeArticleTextForArticleOnlClick(parentItem) {
        var newList = selectedList;
        var articleIndex = newList.findIndex((obj => obj.id === parentItem.id))
        var binarySelectedIndex = newList[articleIndex]?.binaries?.findIndex((obj => obj.isSelected === true));
        if (articleIndex >= 0) {
            if (newList[articleIndex].includeArticleText) {
                newList[articleIndex].includeArticleText = false;
                if (binarySelectedIndex < 0)
                    setDownloadButtonClassName(disableDownloadButtonClassName);
                else
                    setDownloadButtonClassName(enableDownloadButtonClassName);
            }
            else {
                newList[articleIndex].includeArticleText = true;
                setDownloadButtonClassName(enableDownloadButtonClassName);
            }
        }

        setSelectedList([...newList]);
    }

    function handleIncludeArticleTextClick(parentItem) {
        if (parentItem.binaries.findIndex((obj => obj.isSelected === true)) < 0 && parentItem.includeArticleText === true)//case 1: when all binaries are not selected and article text option is also request to deselect
        {
            removeArticleFromSelectedList(parentItem); // case where all binaries already deselected and then article text is also deselected
        }
        else {
            var articleIndex = selectedList.findIndex((obj => obj.id === parentItem.id))

            if (articleIndex >= 0) {
                if (selectedList[articleIndex].includeArticleText) {
                    selectedList[articleIndex].includeArticleText = false;
                    if (selectedList[articleIndex].binaries.findIndex((obj => obj.isSelected === true)) < 0)//case 1: when all binaries are not selected and article text option is also request to deselect
                    {
                        removeArticleFromSelectedList(parentItem)
                    }
                }
                else
                    selectedList[articleIndex].includeArticleText = true;
            }

            setSelectedList([...selectedList]);
        }
    }

    function handleSelectAllCheckboxClick() {
        if (!selectAllChecked) {
            // if select all checked false
            var newSelectedList = [];
            checkAllArticles(articleLabels, newSelectedList);
            setSelectedList(newSelectedList);
            setDownloadButtonClassName(enableDownloadButtonClassName); //when clicked Select All false then disable download button
        }
        else {
            setSelectedList([]);
            setDownloadButtonClassName(disableDownloadButtonClassName);
        }

        setSelectAllChecked(prevVal => !prevVal);
    }

    function handleIncludeAnnotationsClick() {
        setIncludeAnnotationsChecked(prevVal => !prevVal);
    }

    function isArticleSelected(id) {
        var selected = false;
        selectedList.forEach((item) => {
            if (item.id === id) {
                selected = true;
            }
        })
        return selected;
    }
    function recurseSelectedChildren(item) {
        if (item?.children || item?.children?.length > 0) {
            if (item.parentId !== null) {
                const index = topicsToAddToDownload.indexOf(item.parentId);
                if (index > -1) { // only splice array when item is found
                    topicsToAddToDownload.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            item.children.map((childItem) => {
                topicsToAddToDownload.push(childItem.id);
                recurseSelectedChildren(childItem);
            })
        }
    }
    function recurseSelectedContentItem(item) {

        if (topicsToAddToDownload?.includes(item.id)) {
            publicationContentItems.push(item);
        }
        else {
            //ELSE - as if we added teh current item, then the children would have been added already!
            if (item.subItems) {
                item.subItems.forEach((subItem, index) => {
                    if (index === 0) {
                        subItem[parentTitleString] = item?.content?.title;
                        subItem[parentGuidString] = item?.id;
                    }
                    recurseSelectedContentItem(subItem);
                })
            }
        }
    }

    function removeArticleFromSelectedList(item) {
        var newArray = [];

        //need to remove the item, all its children, and its parent.
        function getItemChildrenIds(item) {
            if (item.children) {
                item.children.forEach((child) => {
                    idArray.push(child.id);
                    if (child.children && child.children.length > 0) {
                        getItemChildrenIds(child);//recursive call deselect child item till nth level
                    }
                });
            }

            return (idArray);
        }
        var idArray = [];
        var idsToRemove = getItemChildrenIds(item);
        idsToRemove.push(item.parentId); //also need to remove the parent if it exists, it's just a listing page.

        selectedList.forEach((selectedItem) => {
            if (item.id !== selectedItem.id) {
                if (!idsToRemove.includes(selectedItem.id)) {
                    newArray.push(selectedItem); //only keep the items that aren't the item just passed into this removal function, and aren't that item's children.
                }
            }
        })

        if (newArray.length > 0) {
            setSelectedList([...newArray]);
            setDownloadButtonClassName(enableDownloadButtonClassName);
        }
        else {
            setSelectedList([]);
            setDownloadButtonClassName(disableDownloadButtonClassName);
        }
    }

    function setDownloadButtonState() {
        if (selectedDownloadType === selectedDownloadTypeArticle) {
            handleCheckboxClick(articleContentCopy.content[0])
        }

        if (selectedDownloadType === selectedDownloadTypePublication) {
            var newArray = [];
            setSelectedList(newArray);
            if (selectAllChecked) {
                setDownloadButtonClassName(enableDownloadButtonClassName);
            }
            else {
                setDownloadButtonClassName(disableDownloadButtonClassName);
            }
        }
        else {
            setDownloadButtonClassName(enableDownloadButtonClassName);
        }
    }

    // function SetSelectedBinariesToDownload() {
    //     var selectedBinariesList = [];
    //     var tempSelectedSpinePubGuidList = [];
    //     selectedList.forEach((selectedItem) => {
    //         if (selectedItem.binaries) {
    //             selectedItem.binaries.foreach((currentBinary) => {
    //                 if (currentBinary.isSelected) {
    //                     selectedBinariesList.push(currentBinary);
    //                 }
    //             });
    //         }
    //         if (tempSelectedSpinePubGuidList.findIndex(a => a === selectedItem.id) === -1) {
    //             tempSelectedSpinePubGuidList.push(selectedItem.id);
    //         }
    //     });

    //     setSelectedSpinePubGuidList(tempSelectedSpinePubGuidList);
    //     setListToDownload([...selectedBinariesList]);
    // }

    function annotationDropdownFilter(category) {
        if (category.category_guid !== "cat0") {
            setIsAllCategorySelected(false);
            if (selectedCategory.includes(category.category_guid)) {
                let categories = selectedCategory.filter(ele => ele !== category.category_guid);
                setSelectedCategory(prev => [...categories]);
            } else {
                setSelectedCategory(prev => [...prev, category.category_guid]);
            }
        } else {
            setIsAllCategorySelected(prev => {
                if (prev) {
                    setSelectedCategory(prev => []);
                } else {
                    let categories = annnotationCategories.map(ele => ele.category_guid);
                    setSelectedCategory(prev => [...categories]);
                }
                return !prev
            });
        }
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
    }

    function accessibleCheck(e, type) {
        if (e.key === "Enter") {
            setSelectedAnnotationType(type);
        }
    }

    function showSearchbar() {
        const searchbar = document.getElementById('collection-page-search');
        if (!UTILITIESHELPER.isObjectNullorEmpty(searchbar?.style)) { searchbar.style.display = 'block'; }
    }

    const downloadAlertMessage = () => {
        function closeModal() {
            setDownloadAlert(false);
        }
        return (
            <div className="modal download-modal">
                <div className="modal-header">
                    <p>{ARTICLE_DOWNLOAD.HEADER}</p>
                    <button className="modal-close" onClick={closeModal}></button>
                </div>
                <div className="modal-body">
                    <p>{ARTICLE_DOWNLOAD.BODY}</p>
                </div>
            </div>
        )
    }

    //Styling for the download panel loader
    const downloadLoaderStyle = {
        display: downloadClicked ? 'inline' : 'none'
    }
    const downloadLoaderForBinaries = {
        display: isBinaryLoaded ? 'inline' : 'none'
    }
    return (
        <>
            <div className={
                cx({
                    'reading-mode-screen-darken-overlay': isReadingMode,
                    'screen-darken-overlay screen-darken-overlay-fixed': shouldBeFixed,
                    'screen-darken-overlay-adjust-height': !shouldBeFixed && (breadcrumbheight !== null && breadcrumbheight.clientHeight > 36), 'screen-darken-overlay': !shouldBeFixed,
                    'reading-mode-screen-darken-overlay-fixed': isReadingMode && shouldBeFixed
                })}
                onClick={() => {
                    showSearchbar();
                    setDownloadPanelOpen(false)
                }} />
            <div className={
                cx({
                    'reading-mode-download-panel': isReadingMode, 'download-panel download-panel-fixed': shouldBeFixed,
                    'download-panel-fixed-adjust-height': shouldBeFixed && (breadcrumbheight !== null && breadcrumbheight.clientHeight > 36), 'download-panel ': !shouldBeFixed,
                    'reading-mode-download-panel-fixed': isReadingMode && shouldBeFixed,
                })}
                style={{ top: shouldBeFixed ? panel : 82 }}
            >
                <div className="download-panel-wrapper">
                    <div className="download-panel-header">
                        <p>{getLabel("a_DownloadToWord", "Download")}</p>
                        <button className="download-panel-close" title="Close" onClick={() => {
                            showSearchbar();
                            setDownloadPanelOpen(false);
                            setSelectedDownloadType("");
                        }}></button>
                    </div>
                    <div className="download-panel-body">
                        <div className="checkmark-container-row" onClick={() => setSelectedDownloadType(selectedDownloadTypeArticle)}>
                            <input id="article-download" type="radio" checked={selectedDownloadType === selectedDownloadTypeArticle} readOnly={true} /> <span className="checkmark"></span>
                            <label htmlFor="article-download">{UTILITIESHELPER.removeSpecialCharacterFromTitle(articleTitle) + " " + getLabel("b_BinaryDownloadArticlesONly", "(Article Only)")}</label>
                        </div>
                        {articleContentCopy.content.length > 0 && articleContentCopy.content[0].binaries?.length > 0 &&
                            <div>
                                <div className="display article-level-select-document" onClick={() => { setShowArticleBinaries(prevVal => !prevVal); }}>
                                    <div className="binary-panel binary-panel-article">
                                        {getLabel("b_BinaryDownloadSelectDocuments", "Select Documents")}
                                    </div>
                                    <div className={(showArticleBinaries) === false ? "binary-download-open" : "binary-download-close"} ></div>
                                </div>
                                <ShowIf condition={articleContentCopy.content[0].binaries?.length > 0 && showArticleBinaries}>
                                    {
                                        <div className={cx("download-panel-scrollbox download-panel-scrollbox-small", { 'download-panel-scrollbox-active': selectedDownloadType === selectedDownloadTypeArticle })} tabIndex="0">

                                            { /*TODO: Refactor*/}

                                            <div className="checkbox-container-download-panel" key={articleContentCopy.id} >
                                                <Checkbox
                                                    key={1001}
                                                    classNameCheckmark="checkmark-download-panel"
                                                    label={getLabel("b_BinaryDownloadArticleText", "Article Text")}
                                                    isChecked={selectedDownloadType === selectedDownloadTypeArticle && articleContentCopy.content[0].includeArticleText}
                                                    onChange={() => handleIncludeArticleTextForArticleOnlClick(articleContentCopy.content[0])}
                                                />

                                                {articleContentCopy.content[0].binaries?.map((child, binaryIndex) => {
                                                    return (
                                                        <div key={child.contentGUID + "-" + binaryIndex}>
                                                            <Checkbox
                                                                key={`${child.contentGUID}child-${binaryIndex}`}
                                                                classNameCheckmark="checkmark-download-panel"
                                                                label={child.binaryTitle + "(" + child.binaryFormat.toUpperCase().replace('.', '') + ")"}
                                                                isChecked={child.isSelected}
                                                                onChange={() => handleBinaryCheckboxForArticleOnlyClick(articleContentCopy.content[0], child)}
                                                                disabled={selectedDownloadType !== selectedDownloadTypeArticle}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>}
                                </ShowIf>
                                <br />
                            </div>
                        }
                        <ShowIf condition={isAnnotationsEnabled() && !isRestrictedAccessMemberfirm() && !isReadingMode} >
                            <div className={cx("include-annotation", { 'include-annotation-active': selectedDownloadType === selectedDownloadTypeArticle && annState?.annotations.length > 0 })} tabIndex="1">
                                <div className="checkbox-container-include-annotation" >
                                    <Checkbox
                                        key={11111}
                                        classNameCheckmark="checkmark-download-panel"
                                        label={getLabel("h_Includeannotations", "Include annotations")}
                                        isChecked={includeAnnotationsChecked && selectedDownloadType === selectedDownloadTypeArticle}
                                        onChange={handleIncludeAnnotationsClick}
                                        disabled={selectedDownloadType !== selectedDownloadTypeArticle || annState?.annotations.length === 0}
                                    />
                                </div>
                                <ShowIf condition={includeAnnotationsChecked}>
                                    <div className="annotation-panel-dropdown-categories-download">
                                        <AnnotationPanelDropdown
                                            categories={annnotationCategories}
                                            selected={selectedCategory}
                                            onSelect={annotationDropdownFilter}
                                            isAllCategorySelected={isAllCategorySelected}
                                        />
                                    </div>
                                    <div className="ann-sidemenu-radio-container">
                                        <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("viewAll")} onKeyDown={(e) => accessibleCheck(e, "viewAll")} tabIndex={0}>
                                            <input id="annotation-view" type="radio" checked={selectedAnnotationType === "viewAll"} tabIndex={1} />
                                            <label htmlFor="annotation-view" className="radio-categories"> {getLabel("hh_AnnotationsViewAll", "View All")}</label>
                                        </div>
                                        <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("Highlights")} onKeyDown={(e) => accessibleCheck(e, "Highlights")} tabIndex={0}>
                                            <input id="annotation-highlight" type="radio" checked={selectedAnnotationType === "Highlights"} readOnly={true} tabIndex={1} /> <span className="checkmarks" tabIndex={1}></span>
                                            <label htmlFor="annotation-highlight" className="radio-categories"> {getLabel("hh_AnnotationsViewHighlightsOnly", "Highlights Only")}</label>
                                        </div>
                                        <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("Comments")} onKeyDown={(e) => accessibleCheck(e, "Comments")} tabIndex={0}>
                                            <input id="annotation-comment" type="radio" checked={selectedAnnotationType === "Comments"} readOnly={true} tabIndex={1} /> <span className="checkmarks" tabIndex={1}></span>
                                            <label htmlFor="annotation-comment" className="radio-categories">  {getLabel("hh_AnnotationsViewComentsOnly", "Comments Only")}</label>
                                        </div>
                                    </div>
                                </ShowIf>
                            </div>
                        </ShowIf>
                        <div className="checkmark-container-row" onClick={() => {
                            //added this condition to avoid click event when no data available, even though we disabled but still check box is click able so needed this check
                            if (articleLabels.length > 0) {
                                setSelectedDownloadType(selectedDownloadTypePublication)
                            }
                        }}>
                            <input id="publication-download" type="radio" checked={selectedDownloadType === selectedDownloadTypePublication} readOnly={true} /> <span className="checkmark"></span>
                            <label htmlFor="publication-download">{publicationTitle + " " + getLabel("b_BinaryDownloadSelectArticles", "(Select Articles)")}</label>
                        </div>
                        <div className={cx("download-panel-scrollbox", { 'download-panel-scrollbox-active': selectedDownloadType === selectedDownloadTypePublication }, { 'download-panel-scrollbox-annotation-include': includeAnnotationsChecked }
                            , { 'download-panel-scrollbox-annatation-selectdocument': articleContentCopy.content.length > 0 && articleContentCopy.content[0].binaries?.length > 0 && includeAnnotationsChecked },
                            { 'download-panel-scrollbox-selectdocument': articleContentCopy.content.length > 0 && articleContentCopy.content[0].binaries?.length > 0 && !includeAnnotationsChecked })} tabIndex="1">
                            <div className="download-panel-loader" style={downloadLoaderForBinaries} >
                                <div className="main-cont">
                                    <div className="double-lines-spinner"></div>
                                </div>
                            </div>
                            {articleLabels.length > 0 &&
                                <div className="checkbox-container-download-panel">
                                    <Checkbox
                                        key={"00000"}
                                        classNameCheckmark="checkmark-download-panel"
                                        label={getLabel("b_BinaryDownloadSelectAll", "Select All")}
                                        isChecked={selectAllChecked && selectedDownloadType === selectedDownloadTypePublication}
                                        onChange={handleSelectAllCheckboxClick}
                                        onKeyDown={(e) => accessibleCheckboxAll(e)}
                                        disabled={selectedDownloadType !== selectedDownloadTypePublication}
                                    />
                                </div>}
                            {articleLabels.length > 0 && articleLabels.map((item) => {
                                var isArticleSelectedState = isArticleSelected(item.id);
                                return (
                                    <div key={item.id}>
                                        <div className="checkbox-container-download-panel">
                                            <Checkbox
                                                key={item.id + "item"}
                                                classNameCheckmark="checkmark-download-panel"
                                                label={item.title + getUniqueFormat(item?.binaries)}
                                                isChecked={isArticleSelectedState && selectedDownloadType === selectedDownloadTypePublication}
                                                onChange={() => handleCheckboxClick(item)}
                                                disabled={selectedDownloadType !== selectedDownloadTypePublication}
                                                showBinaryPanel={isArticleSelectedState && (item?.binaries?.length > 0 ? true : false) && (selectedDownloadType === selectedDownloadTypePublication)}
                                                displayBinaryHadler={displayBinaryHadler}
                                                parentId={item.id}
                                                binaryCount={item?.binaries?.length > 0 ? item.binaries.length : 0}
                                                accordianState={(showHideBinary && displayBinary === item.id && isArticleSelectedState) === true ? "close" : "open"}
                                            />
                                            <ShowIf condition={showHideBinary && displayBinary === item.id && isArticleSelectedState}>
                                                <div className="checkbox-container-download-panel-child download-panel-article-text-checkbox">
                                                    <Checkbox
                                                        key={1001 + "grandchild"}
                                                        classNameCheckmark="checkmark-download-panel"
                                                        label={getLabel("b_BinaryDownloadArticleText", "Article Text")}
                                                        isChecked={item.includeArticleText && selectedDownloadType === selectedDownloadTypePublication}
                                                        onChange={() => handleIncludeArticleTextClick(item)}
                                                    />
                                                </div>
                                                {item.binaries?.map((grandchild, index) => {
                                                    return (
                                                        <div key={grandchild.ContentGUID + "-" + index}>
                                                            <div className="checkbox-container-download-panel-child">
                                                                <Checkbox
                                                                    key={index + "grandchild"}
                                                                    classNameCheckmark="checkmark-download-panel"
                                                                    label={grandchild.binaryTitle + "(" + grandchild.binaryFormat.toUpperCase().replace('.', '') + ")"}
                                                                    isChecked={grandchild.isSelected && selectedDownloadType === selectedDownloadTypePublication} //{selectedBinaries} //{(isArticleSelected(item.id) && selectedList.filter((e) => e.id === item.id))}
                                                                    onChange={() => handleBinaryCheckboxClick(item, grandchild)}
                                                                    disabled={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </ShowIf>
                                        </div>
                                        {generateLabelCheckboxes(item.children, 1)}
                                    </div>
                                )
                            })}
                        </div>
                        <br />
                    </div>
                    <div className="download-panel-footer">
                        <div className="download-button-div">
                            <div className={downloadButtonClassName} data-testid="downloadbutton" onClick={() => {
                                setDownloadClicked(true)
                            }}>{getLabel("b_BinaryDownloadButton", "DOWNLOAD")}</div>
                        </div>
                        <div className="download-panel-loader" style={downloadLoaderStyle} >
                            <div className="main-cont">
                                <div className="double-lines-spinner"></div>
                            </div>
                        </div>
                    </div>
                    <ShowIf condition={downloadAlert}>
                        {downloadAlertMessage()}
                    </ShowIf>
                </div>
            </div>
        </>
    );
}

DownloadPanel.propsTypes = {
    currentArticleHtml: "Html"
}

export default DownloadPanel;