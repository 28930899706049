import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import { logs, page } from '../../helpers/log'
import RETRIEVALSERVICES from '../../services/rp-service'
import UTILITIESHELPER from '../../helpers/UtilitiesHelper'
import {
    usePageContext,
    usePageContextLabels,
    usePageContextSettings,
} from '../../contexts/PageContext'
import { useLoadingContext } from '../../contexts/LoadingContext'
import { useThemeContext } from '../../contexts/ThemeContext'
import ShowIf from '../Common/ShowIf'
import WormholeNotification from '../Notification/WormholeNotification'
import CollectionSearchbar from './CollectionSearchbar'
import { useSearchResultContext } from '../../contexts/SearchResultContext'
import SearchHelpers from '../../helpers/SearchHelpers'
import RecentViewedService from '../../helpers/RecentlyViewedServices'
import { useLocation } from 'react-router-dom'
import RecentlyViewedHelper from '../../helpers/RecentlyViewedHelper'
import { ErrorCodes, ErrorMessages } from '../Constants/Errors'
import CatalogCards from './CatalogCards'
import useCatalogSearchQuery from '../../hooks/useCatalogSearchQuery'
import ChatbotModal from '../ChatBot/ChatbotModal';
import cx from "classnames";
import ResearchAssistantErrorMessage from '../Common/ResearchAssistantErrorMessage'
import { useResearchAssistantContext, ACTIONS } from '../../contexts/ResearchAssistantContext'
import { ACTION, useFolioPickerContext } from '../../contexts/FolioPickerContext';

const Catalog = () => {
    const params = useParams()
    const { knowledgeDomain, memberFirm, language } = params
    const [data, setData] = useState({})
    const [catalogs, setCatalogs] = useState([])
    const { isLoading, setIsLoading } = useLoadingContext()
    const [isResultLoading, setIsResultLoading] = useState(false)
    const { state, folioDispatch } = useFolioPickerContext();
    const {
        setPageTitle,
        currentTier, currentIndustry,
        tierVal,
        industryVal,
        searchPhrase,
        setSearchPhrase,
        contextMemberFirm,
        getMemberFirmID,
        setIsResearchAssistantEnabled,
        isResearchAssistantEnabled,
        countryKDSettings,
        context
    } = usePageContext()
    const {
        isDashboardEnabled,
        isRecentlyViewedEnabled,
    } = usePageContextSettings()
    const { getLabel,getMFLabel } = usePageContextLabels()
    const { isDarkMode } = useThemeContext()
    const [isFixed] = useState(false)
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [isSearchSubmit, setSearchSubmit] = useState(false)
    const searchProps = {
        eventType: 'search',
        searchLocation: window.location.href,
        jobTitle: UTILITIESHELPER.getSessionStorage('Level'),
        memberFirm: UTILITIESHELPER.getSessionStorage(
            'research-portal-member-firm'
        ),
    }
    const location = useLocation()
    const {
        filteredSearchResults,
        setFilteredSearchResults,
    } = useSearchResultContext()
    const [showNoResults, setShowNoResults] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [searchOrder, setSearchOrder] = useState([
        '60194861', //folios
        '60194866', //manuals
        '60194865', //standards
        '60194864', //Deloitte-communications
        '65511719', //Announcements
    ])
    const [counterSectionsResult, setCounterSectionsResult] = useState(0)
    const [catalogSearchPhrase, setCatalogSearchPhrase] = useState('')
    const wormholeStyling = { marginRight: '35px', marginTop: '7px' }
    const [showResaechAssistantModal, setshowResaechAssistantModal] = useState(false);
    const [searchResearchAssistant, setSearchResearchAssistant] = useState(false);
    const [isSearchPhraseExist, setIsSearchPhraseExist] = useState(false);
    const isResearchAssistantEnabledForKD =  UTILITIESHELPER.isResearchAssistantEnabledForKD(countryKDSettings, context.memberFirm, context.knowledgeDomain);
    const isResearchAssistantActiveForUser = isResearchAssistantEnabled && isResearchAssistantEnabledForKD ? true : false;
    const { dispatch, state: raState } = useResearchAssistantContext();
    const [expandMore, setExpandMore] = useState(true);

    useEffect(() => {
        setIsSearchActive(false);
        const searchValueRA = localStorage.getItem('research-assistant-searchphrase');
        if(searchValueRA){
          setIsSearchPhraseExist(true);
          setSearchPhrase(searchValueRA);
          localStorage.removeItem('research-assistant-searchphrase');
        }

        window.addEventListener("keydown", keyDownHandler);
        return () => {
            window.removeEventListener("keydown", keyDownHandler);
        };
 
    }, []);

    const keyDownHandler = (event) => {
        if (event.ctrlKey && event.key === "0") {
            setIsResearchAssistantEnabled(!isResearchAssistantEnabled);
        }};

    useEffect(() => {
        const fetchCatalogData = async () => {
            try {
                setPageTitle('')
                setIsLoading(true)
                setshowResaechAssistantModal(false);
                document.body.style.overflow = 'auto';
        const res = await RETRIEVALSERVICES.retrieveCatalog()
        logs.debug(page.Catalog, 'retrieveCatalog', 'retrieveCatalog:res', res)
         
        const taxonomyData= res.CatalogCollection[0].taxonomylist;
       

        const payload = {
          folioLists: taxonomyData,
          memberFirm:context.memberFirm
        }
      
        folioDispatch({
          type: ACTION.FOLIOPICKER_LOAD_TAXONOMY,
          payload: payload,


      })

        setData(res)
        setPageTitle(get(res, 'pageTitle'))
      } catch (err) {
        logs.error(
          page.Catalog,
          'fetchRecentHistory',
          ErrorMessages.recentHistoryData,
          err,
          { eventId: ErrorCodes.Catalog }
        )
      }
    }

    fetchCatalogData()
    const mf = UTILITIESHELPER.getSessionStorage('MemberFirm')
    logs.trackPageView({
      name: 'Catalog - ' + knowledgeDomain + ' ' + mf,
      properties: {
        memberFirm: mf,
        serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
        level: UTILITIESHELPER.getSessionStorage('Level'),
      },
    })

    return () => setPageTitle('')
    }, [memberFirm, language, knowledgeDomain, currentIndustry, currentTier, tierVal, industryVal]) //eslint-disable-line

  const shouldShowCard = (collection) => {
    const friendlyUrl = get(collection, 'collectionType', '')
    const viewAllLink =
      friendlyUrl.indexOf('dashboard') > -1
        ? [friendlyUrl].join('/')
        : ['collection', friendlyUrl].join('/')

    let show = true
    // If the collection is a dashboard and the dashboard is disabled, hide the card
    if (viewAllLink.indexOf('dashboard') > -1 && !isDashboardEnabled()) {
      show = false
    }

    // If the collection is a recently viewed and the recently viewed is disabled, hide the card
    if (
      collection.collectionType.indexOf('history') > -1 &&
      !isRecentlyViewedEnabled()
    ) {
      show = false
    }

    // By default show the card, add exceptions above if needed
    return show
  }

  useEffect(() => {
    const fetchCatalogHistoryData = async (_data) => {
      const resultLimit = _data?.CatalogCollection?.find(
        (q) => q.collectionType === 'dashboard/history'
      )?.resultLimit
      const recentHistoryList = JSON.parse(UTILITIESHELPER.getLocalStorage('recent-view-history'))
      const resCatalogHistory = recentHistoryList?.[context.memberFirm]?.[context.knowledgeDomain]?.slice(0,3) || []
      logs.debug(
        page.Catalog,
        'fetchCatalogHistory',
        'fetchCatalogHistory:resCatalogHistory',
        resCatalogHistory
      )
      const recentHistory = RecentlyViewedHelper.generateRecentHistoryLinks(
        resCatalogHistory
      )
      const dataWithHistory = RecentlyViewedHelper.setRecentHistoryLinks(
        data,
        recentHistory
      )
      let catalogCollections = get(dataWithHistory, 'CatalogCollection', [])
      const filteredCatalogs = catalogCollections.filter((catalog) =>
        shouldShowCard(catalog)
      )

      setCatalogs(filteredCatalogs)
      setIsLoading(false)
      if(isSearchPhraseExist){
        handleSearchTrigger(searchPhrase);
      }
    }

    const memberFirmID = getMemberFirmID()

    if (!UTILITIESHELPER.isNullOrUndefined(memberFirmID)) {
      let catalogCollections = get(data, 'CatalogCollection', [])
      if (catalogCollections.length > 0) {
        //If recently viewed is enabled, we need to load the history into this object before we load the catalog boxes
        if (isRecentlyViewedEnabled()) {
          fetchCatalogHistoryData(data)
        } else {
          const filteredCatalogs = catalogCollections.filter((catalog) =>
            shouldShowCard(catalog)
          )

          setCatalogs(filteredCatalogs)
          setIsLoading(false)
        }
      }
    }
  }, [contextMemberFirm, data]) //eslint-disable-line

  const handleSearchTrigger = (typedSearchPhrase) => {
    if (
        searchPhrase !== '' &&
        typedSearchPhrase !== '' &&
        String(typedSearchPhrase)?.length > 1
    ) {
        setSearchResearchAssistant(!searchResearchAssistant);
        setshowResaechAssistantModal(true);
        document.body.style.overflow = 'hidden';
        setExpandMore(true);
        setSearchSubmit(true)
        if (
            String(typedSearchPhrase.toUpperCase()) ===
            catalogSearchPhrase.toUpperCase() &&
            catalogSearchQueryresults
        ) {
            setFilteredSearchResults(catalogSearchQueryresults)
            setShowNoResults(
                Object.values(catalogSearchQueryresults).every(
                    (arr) => Array.isArray(arr) && arr.length === 0
                )
                    ? true
                    : false
            )
        } else {
            setShowNoResults(false)
            setIsResultLoading(true)
            setCatalogSearchPhrase(typedSearchPhrase)
        }
    } else {
        setSearchSubmit(false)
        setIsResultLoading(false)
        setFilteredSearchResults([])
        setShowNoResults(false)
    }
}

  const { data: catalogSearchQueryresults } = useCatalogSearchQuery(
    catalogSearchPhrase,
    isSearchSubmit
  )
  useEffect(() => {
    if (catalogSearchQueryresults && isSearchSubmit === true) {
      if (String(catalogSearchPhrase)?.length > 1 && catalogSearchPhrase) {
        searchProps.searchText = catalogSearchPhrase
        logs.event('search', searchProps)

        Object.keys(catalogSearchQueryresults).forEach((key) => {
          if (!searchOrder.some((item) => item === key)) {
            if (key === 'folio') {
              setSearchOrder([key, ...searchOrder])
            } else {
              setSearchOrder([...searchOrder, key])
            }
          }
        })

        determineCounterSectionsResult(catalogSearchQueryresults)

        if (Object.keys(catalogSearchQueryresults)?.length > 0) {
          setIsResultLoading(false)
          setFilteredSearchResults(catalogSearchQueryresults)
          setShowNoResults(
            Object.values(catalogSearchQueryresults).every(
              (arr) => Array.isArray(arr) && arr.length === 0
            )
              ? true
              : false
          )
          setSearchPhrase(catalogSearchPhrase)
          setIsSearchActive(true)
          return
        } else {
          setIsResultLoading(false)
          setFilteredSearchResults([])
          setShowNoResults(true)
          return
        }
      }
    }
  }, [catalogSearchQueryresults]) //eslint-disable-line

  function determineCounterSectionsResult(results) {
    let _counterSectionsResult = 0
    Object.keys(results).map((key) => {
      //eslint-disable-line
      const sectionResultCount = results[key]?.length ?? 0
      _counterSectionsResult = _counterSectionsResult + sectionResultCount
    })

    setCounterSectionsResult(_counterSectionsResult)
  }

  const closeErrorNotification=()=>{
    let errorPayload = {
        hasError:false
    }

    dispatch({
        type: ACTIONS.RA_HIDE_ERROR,
        payload: errorPayload,
    })
}

    return (
        <>
            <ShowIf condition={!isDarkMode && data.backgroundImageUrl}>
                <img
                    className='catalog-background'
                    src={data.backgroundImageUrl}
                    alt='Background Alt text'
                />
            </ShowIf>
            <ShowIf condition={raState.hasError}>
                 <ResearchAssistantErrorMessage errorType={raState.errorType} closeErrorNotification={closeErrorNotification}/>
            </ShowIf>
            <div className='catalog-wormhole-div'>
                <WormholeNotification style={wormholeStyling} />
            </div>
            
            <ShowIf condition={!isLoading}>
                <div className={cx("chatbot-catalog", { "nonchatbot-catalog": !isResearchAssistantActiveForUser })}>
                    <div className="d-flex chatbot-box-top-row">
                        <div className="chatbot-icon-container">
                            <img className="chatbot-box-icon" alt="SearchText" />
                        </div>
                        <div className="chatbot-heading">
                            <ShowIf condition={isResearchAssistantActiveForUser}>
                                <h2 className="chatbot-box-name">
                                    {getLabel('ra_SearchOrAsk', 'Search or Ask')}
                                </h2>
                            </ShowIf>
                            <ShowIf condition={!isResearchAssistantActiveForUser}>
                                <h2 className="chatbot-box-name">
                                    Search
                                </h2>
                            </ShowIf>
                            <ShowIf condition={isResearchAssistantActiveForUser}>
                                <div className="chatbot-box-description">
                                  {getLabel('ra_FindInformationFomGenai', 'Find information from Research Portal using the AI Research Assistant')}
                                </div>
                            </ShowIf>
                            <ShowIf condition={!isResearchAssistantActiveForUser}>
                                <div className="chatbot-box-description" data-testid="chatbot-description">
                                  {getLabel('ra_LocateInformationFromRP','Locate information from Research Portal')}
                                </div>
                            </ShowIf>
                        </div>
                    </div>
                    <div className="catalog-searchbar-container">
                        <div className="catalog-searchbar-chatbot" id="catalog-page-search">

                            <CollectionSearchbar
                                placeholder={isResearchAssistantActiveForUser ? "E.g., What is materiality? or Internal Control Guide" : " "}
                                className="catalog-searchbar-input-chatbot"
                                autocomplete="off"
                                parentOnTrigger={handleSearchTrigger}
                                isFixed={isFixed}
                                calledFrom={page.Catalog}
                                isResearchAssistantEnabled={isResearchAssistantActiveForUser}
                                classNameForm="chatbot-form"
                            />
                        </div>
                    </div>
                </div>
            </ShowIf>
            <div className={cx('chatbot-modal', {'ra-error-notification' : raState.hasError})}>
                <ChatbotModal isResultLoading={isResultLoading}
                    searchOrder={searchOrder}
                    filteredSearchResults={filteredSearchResults}
                    isSearchActive={isSearchActive}
                    counterSectionsResult={counterSectionsResult}
                    showResults={showResults}
                    catalogs={catalogs}
                    showResaechAssistantModal={showResaechAssistantModal}
                    setshowResaechAssistantModal={setshowResaechAssistantModal}
                    isResearchAssistantEnabled={isResearchAssistantActiveForUser}
                    parentOnTrigger={handleSearchTrigger}
                    searchResearchAssistant={searchResearchAssistant}
                    setSearchResearchAssistant={setSearchResearchAssistant}
                    expandMore = {expandMore}
                    setExpandMore = {setExpandMore}
                />
            </div>
            <CatalogCards catalogs={catalogs} isLoading={isLoading} />
        </>
    )
}

export default Catalog
