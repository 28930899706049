import React, { useState, useEffect } from 'react';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import ShowIf from '../Common/ShowIf';
import LINKHELPERS from '../../helpers/LinkHelpers';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import SearchHelpers from '../../helpers/SearchHelpers';
import ChatBotCatalogSearchResultItem from '../../components/ChatBot/ChatBotCatalogSearchResultItem';
import cx from 'classnames';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { ErrorTypes } from '../Constants/Errors';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { appInsightUIMapping } from '../../helpers/log';
import ChatbotResearchAssistant from './ChatbotResearchAssistant';
import useDCSService from '../../services/useDCSService';
import { RESEARCH_ASSISTANT_FILTERS,  RESEARCH_ASSISTANT} from '../Constants/Constants';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { ACTIONS, useResearchAssistantContext } from '../../contexts/ResearchAssistantContext';
import TosDeclaration from './TosDeclaration';

function ChatbotModal({ isResultLoading,
    searchOrder, filteredSearchResults,
    isSearchActive, counterSectionsResult,
    showResults, catalogs, showResaechAssistantModal, setshowResaechAssistantModal, isResearchAssistantEnabled, className, parentOnTrigger, searchResearchAssistant, setSearchResearchAssistant, expandMore, setExpandMore }) {
    const params = useParams();
    const { knowledgeDomain, memberFirm, language } = params;
    const { searchPhrase, setSearchPhrase, setNotification, tier ,getMemberFirmID, context, userCountry } = usePageContext();
    const { getLabel, getMFLabel } = usePageContextLabels();
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));
    const [researchAssistantResponse, setResearchAssistantResponse] = useState();
    const [searchContent, setSearchContent] = useState('')
    const { retrieveChatBotSearchResult } = useDCSService();
    const [isResearchAssistantLoading, setIsResearchAssistantLoading] = useState(false);
    const { dispatch, state: raState, tosAgreed, setTosAgreed } = useResearchAssistantContext();
    const [raApiFailure, setRaApiFailure ] = useState(null);

    useEffect(() => {
        setSearchContent(searchPhrase)
    }, [searchPhrase])

    function getCatalogTitleByKey(key) {
        let catalogTitle = "";
        let viewAllLink = "";
        let friendlyUrl = "";

        const isFolio = key === "folio";

        catalogs.forEach((catalog) => {
            const isMatchingCatalog = isFolio ? catalog.collectionType === 'folio' : catalog?.publicationType === key;

            if (isMatchingCatalog) {
                catalogTitle = getMFLabel(get(catalog, 'title'), []);
                friendlyUrl = get(catalog, 'collectionType', '');
                viewAllLink = ((friendlyUrl.indexOf("dashboard") > -1) ? friendlyUrl : [knowledgeDomain, "collection", friendlyUrl].join("/")) + '?' + SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase));
            }
        })


        return { 'catalogTitle': catalogTitle, 'viewAllLink': viewAllLink, 'friendlyUrl': friendlyUrl };
    }

    const handleAskFollowUpButton = () => {
        UTILITIESHELPER.setRATakeOverData(context, tier,  JSON.stringify(researchAssistantResponse), getMemberFirmID());
        window.open(RESEARCH_ASSISTANT.RA_CONVO_URL, "_blank");
    }

    const getIconSrc = (friendlyUrl) => {
        if (!UTILITIESHELPER.isStringNullorEmpty(friendlyUrl)) {
            if (friendlyUrl === "folio")
                return '/images/folio-tile.svg';
            else if (friendlyUrl === "manual")
                return '/images/manual-tile.svg';
            else if (friendlyUrl === "standards")
                return '/images/standard-tile.svg';
            else if (friendlyUrl === "deloitte-communications")
                return '/images/deloitte-tile.svg';
            else if (friendlyUrl === "professional-news")
                return '/images/external-tile.svg';
            else
                return '/images/undefined-doc.svg';
        }
        else {
            return '/images/undefined-doc.svg';
        }
    }

    useEffect(() => {
        const fetchChatBotResponse = async () => {
            if(!showResaechAssistantModal) return
            try {
                    setIsResearchAssistantLoading(true);
                    //This Payload will get updated in next upcoming sprint
                    var payload = {
                        consentToRetainData: true,
                        userLibrariesSelected: [`${getMemberFirmID()}`],
                        userFilterSelected: [
                            {
                                filterField: RESEARCH_ASSISTANT_FILTERS.Frameworks,
                                filterID: tier[memberFirm] && tier[memberFirm][knowledgeDomain] && tier[memberFirm][knowledgeDomain].length ? tier[memberFirm][knowledgeDomain].map(x => x.id) : []
                            },
                            {
                                filterField: RESEARCH_ASSISTANT_FILTERS.KnowledgeDomain,
                                filterID: [`${CONTEXTHELPERS.getknowledgeDomainId(knowledgeDomain)}`]
                            }
                        ],
                        currentMessage: searchPhrase,
                        previousMessages: []
                    }
    
                    let chatbotres = await retrieveChatBotSearchResult(payload);
                    if(chatbotres){
                        if(chatbotres instanceof Error){
                            setRaApiFailure(chatbotres.message);
                            setResearchAssistantResponse({});
                        }
                        else{
                            chatbotres.tier = tier[memberFirm] && tier[memberFirm][knowledgeDomain] && tier[memberFirm][knowledgeDomain].length ? tier[memberFirm][knowledgeDomain].sort((a, b) => a.id - b.id).map(x => x.value) : [];
                            chatbotres.memberFirm = memberFirm
                            chatbotres.knowledgeDomain=knowledgeDomain
                            setResearchAssistantResponse(chatbotres)
                            setRaApiFailure(null);
                        }
                    }
                    setIsResearchAssistantLoading(false);
            }
            catch (err) {
                setResearchAssistantResponse({});
                setIsResearchAssistantLoading(false);
                setRaApiFailure(null);
            }
        }

        if (getMemberFirmID() && isResearchAssistantEnabled) {
            fetchChatBotResponse();
        }

    }, [searchResearchAssistant])//eslint-disable-line

    const handleIAgreeClick = async () => {
        UTILITIESHELPER.setLocalStorage("ra_tou_agreed_status", true);
        UTILITIESHELPER.setLocalStorage("ra_tou_agreed_version", String(window.DART.ENV.RA_TOSDESC_VERSION));
        setTosAgreed(true);
    };

    function closeModal() {
        setExpandMore(true);
        setIsResearchAssistantLoading(false);
        setshowResaechAssistantModal(false);
        document.body.style.overflow = 'auto';
        if(raState.selectedInfoIndex > -1) {
            dispatch({
                type: ACTIONS.RA_INFO_INDEX,
                payload: null
            })
        }
    }
    const handleSubmit = event => {
        event.preventDefault();
        setExpandMore(true);
        const _searchPhrase = searchContent.trim();
        if (UTILITIESHELPER.isStringNullorEmpty(_searchPhrase) || _searchPhrase.length < 2) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }
        if (SearchHelpers.IsSearchPrefixWithWildcards(_searchPhrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'CollectionSearchbar' });
            return;
        }
        if(raState.selectedInfoIndex > -1) {
            dispatch({
                type: ACTIONS.RA_INFO_INDEX,
                payload: null
            })
        }
    };

    const handleChange = event => {
        setSearchContent(event.target.value)
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setExpandMore(true);
            setSearchPhrase(searchContent)
            parentOnTrigger(searchContent);
            setSearchResearchAssistant(!searchResearchAssistant);
        }
    }

    return (
        <div>
            <div className={cx("chat-boat-catalog-modal", { 'hide': !showResaechAssistantModal })}>
                <div className="chat-boat-catalog-modal-header">
                    <button className="chat-boat-catalog-modal-close" onClick={closeModal} title="Close"></button>
                </div>
                <div className="chat-boat-catalog-modal-body">
                    <div className="chat-boat-catalog-modal-search-block">
                        <form onSubmit={handleSubmit} id="form-chat-boat-catalog-search" data-testid="form-chat-boat-catalog-search" autoCapitalize='off'>
                            <input
                                className={cx("catalog-chatboat-modal-input", className)}
                                value={searchContent || ''}
                                onChange={handleChange}
                                autoComplete="off"
                                id="catalog-chatboat-modal-search"
                                onKeyDown={handleKeyDown}
                                data-testid="catalog-chatboat-modal-search-input"
                            />
                        </form>
                    </div>
                    <div className="chat-boat-catalog-modal-content-block">
                        <ShowIf condition={isResearchAssistantEnabled && tosAgreed}>
                            <ChatbotResearchAssistant isResearchAssistantLoading={isResearchAssistantLoading} expandMore={expandMore} setExpandMore={setExpandMore} researchAssistantResponse={researchAssistantResponse} searchPhrase={searchPhrase} handleAskFollowUpButton={handleAskFollowUpButton} errorMessage={raApiFailure}/>
                        </ShowIf>
                        <ShowIf condition={isResearchAssistantEnabled &&  !tosAgreed}>
                            <TosDeclaration handleIAgreeClick={handleIAgreeClick} isConvoPage={false} userCountry = {userCountry} />
                        </ShowIf>
                        <div className='modal-serach-result'>
                            <div className='search-result-title' data-testid='search-result-title'>
                                <ShowIf condition={isSearchActive && filteredSearchResults !== null && !isResultLoading && counterSectionsResult > 0}>
                                    <label>{getLabel('ra_TopSearchResultsCollection', 'Top Search Results from Each Collection')}</label>
                                </ShowIf>
                            </div>
                            <ShowIf condition={isResultLoading}>
                                <div className='chatbot-catalog-search-result-panel'>
                                    {
                                        searchOrder.map((key, idx) => {//eslint-disable-line
                                            const obj = getCatalogTitleByKey(key);
                                            if (obj.catalogTitle !== "") {
                                                return (
                                                    <div key={idx} className="catalog-search-result-container">
                                                        <div className='d-flex'>
                                                            <div className="icon-container">
                                                                <img className="catalog-box-icon" src={getIconSrc(obj.friendlyUrl)} alt="ico" />
                                                            </div>
                                                            <div className="catalog-search-result-container-title" data-testid='catalog-search-result-container-title'>{obj.catalogTitle.toUpperCase()}</div>
                                                            <div className="catalog-search-result-container-title-link-disabled">
                                                                <div className='collection-search-view-all-disabled'></div>
                                                            </div>
                                                        </div>
                                                        {
                                                            <div className="cf-right shimmerArticleCard shimmerArticleWrapper catalog-search-result-panel-warapperShimmer" >
                                                                <div className="article-content-container catalog-search-result-panel-article-content-containerShimmer">
                                                                    <div className="article">
                                                                        <div className="body">
                                                                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine  catalog-search-result-panel-shimmerLine"></div>
                                                                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine  catalog-search-result-panel-shimmerLine"></div>
                                                                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine  catalog-search-result-panel-shimmerLine"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }

                                        })
                                    }
                                </div>
                            </ShowIf>
                            <ShowIf condition={(showResults && filteredSearchResults?.length < 1)}>
                                <div className='chatbot-catalog-search-result-panel'>
                                    <div className="catalog-search-no-results">
                                        {getLabel("s_SearchReturnedNoResults", "Your search returned no results")}
                                    </div>
                                </div>
                            </ShowIf>
                            <ShowIf condition={isSearchActive && filteredSearchResults !== null && !isResultLoading && counterSectionsResult > 0}>
                                <div className='chatbot-catalog-search-result-panel-search-active'>
                                    {
                                        searchOrder.map((key, idx) => { //eslint-disable-line
                                            if (filteredSearchResults[key]) {
                                                const obj = getCatalogTitleByKey(key);
                                                if (obj.catalogTitle !== "") {
                                                return (
                                                    <div key={idx} className="catalog-search-result-container">
                                                        <div className='d-flex justify-between'>
                                                            <div className="icon-container">
                                                                <img className="catalog-box-icon" src={getIconSrc(obj.friendlyUrl)} alt="ico" />                                                           
                                                            </div>
                                                            <div className="catalog-search-result-container-title" data-testid='catalog-search-result-container-title'>{obj.catalogTitle.toUpperCase()}</div>
                                                            <div className="catalog-search-result-container-title-link">
                                                                <a href={LINKHELPERS.sanitizeUrl(obj.viewAllLink)} className='collection-search-view-all collection-search-view-all-ra'>{getLabel('ra_ViewAllResults','View All Results')}</a>
                                                            </div>
                                                        </div>

                                                        {filteredSearchResults[key]?.map((result, index) => { //eslint-disable-line
                                                            if (index < get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)) {
                                                                result["url"] = LINKHELPERS.resolveSearchLink(result, memberFirm, language, knowledgeDomain, obj.friendlyUrl, searchPhrase, catalogs, true);
                                                                return (
                                                                    <ChatBotCatalogSearchResultItem key={index + '_' + key} viewAllResultsUrl={LINKHELPERS.sanitizeUrl(obj.viewAllLink)} {...result} />
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )
                                            }
                                            }
                                        })
                                    }
                                </div>
                            </ShowIf>
                            <ShowIf condition={isSearchActive && filteredSearchResults !== null && !isResultLoading && counterSectionsResult === 0}>
                                <div className='chatbot-catalog-search-result-panel'>
                                    <div className="chatbot-catalog-search-no-results">
                                        {getLabel("s_SearchReturnedNoResults", "Your search returned no results")}
                                    </div>
                                </div>
                            </ShowIf>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatbotModal
